import WebServiceWrapper from "./WebServiceWrapper";

export const TreatmentResponseService = {
    GetAllEpcoritamabResponse,
    SaveTreatmentResponse,

    GetTreatmentPriorEpcoritamabData,
    SaveTreatmentPriorEpcoritamabData,
    GetTreatmentAfterEpcoritamabData,
    SaveTreatmentAfterEpcoritamabData,
    GetAllCarTCellTherapyData,
    SaveCarTCellTherapy,
    IsCARTCellTherapySelected
};

function GetAllTreatmentResponse(patientId) {
    return WebServiceWrapper.Get(
        `/treatmentResponse/GetAllTreatmentResponseData?patientId=${patientId}`
    );
}

function SaveTreatmentResponse(payload) {
    return WebServiceWrapper.PostWithHeader(
        "/treatmentResponse/SaveTreatmentResponseData",
        payload
    );
}

function GetAllCarTCellTherapyData(patientId) {
   
    return WebServiceWrapper.Get(
        `/treatmentResponse/GetAllCarTCellTherapyData?patientId=${patientId}`
    );
}

function SaveCarTCellTherapy(payload) {
    
    return WebServiceWrapper.PostWithHeader(
        "/treatmentResponse/SaveCarTCellTherapy",
        payload
    );
}

function GetTreatmentAfterEpcoritamabData(patientId, lotId) {
    return WebServiceWrapper.Get(`/treatmentResponse/GetTreatmentAfterEpcoritamabData?patientId=${patientId}&lotNum=${lotId}`);
}

function SaveTreatmentAfterEpcoritamabData(payload) {
    console.log("pay",payload)
    return WebServiceWrapper.PostWithHeader(
        "/treatmentResponse/SaveTreatmentAfterEpcoritamabData",
        payload
    );
}


function GetAllEpcoritamabResponse(patientId) {
    return WebServiceWrapper.Get(`/treatmentResponse/GetAllEpcoritamabResponseData?pid=${patientId}`);
}

function GetTreatmentPriorEpcoritamabData(patientId, lotId) {
    return WebServiceWrapper.Get(`/treatmentResponse/GetTreatmentPriorEpcoritamabData?patientId=${patientId}&lotNum=${lotId}`);
}
function SaveTreatmentPriorEpcoritamabData(payload) {
    return WebServiceWrapper.PostWithHeader("/treatmentResponse/SaveTreatmentPriorEpcoritamabData", payload);
}

function IsCARTCellTherapySelected(patientId) {
    return WebServiceWrapper.Get(`/treatmentResponse/IsCARTCellTherapySelected?patientId=${patientId}`);
}