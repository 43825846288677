import CryptoJS, { format } from "crypto-js";
import { ArchiveService } from "../WebApiServices/Archive.service";
import ArchiveModel from "../DataModels/ArchiveModel";
import { DatePart, DayMonthUnknown, DefaultDate } from "./Enums";
import moment from "moment";
const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);


/* <summary>
date: 06-04-2024
Name: AP
description: Get logged in user's ID
<summary>*/
export const GetLoggedInUserID = () => {
  const userId = GetLocalStorageData("userId");
  return Number(userId);
};

/* <summary>
date: 06-04-2024
Name: AP
description: Add data to local storage in encrypted form
<summary>*/
export const AddLocalStorageData = (storageKey, value) => {

  const encryptedValue = EncryptUsingAES256(JSON.stringify(value));
  const encryptedKey = EncryptUsingAES256(storageKey);
  if (localStorage.getItem(storageKey)) {
    localStorage.removeItem(storageKey);
  }
  localStorage.setItem(encryptedKey, encryptedValue);
};

/* <summary>
date: 06-04-2024
Name: AP
description: get decrypted data from local storage
<summary>*/
export const GetLocalStorageData = (storageKey) => {

  const encryptedKey = EncryptUsingAES256(storageKey);
  const encryptedValue = localStorage.getItem(encryptedKey) ?? undefined;
  try {
    if (encryptedValue) {
      return JSON.parse(DecryptUsingAES256(encryptedValue));
    }
  } catch (error) { }
  return encryptedValue;
};

/* <summary>
date: 06-04-2024
Name: AP
description: get decrypted data from local storage
<summary>*/
export const RemoveLocalStorageData = (storageKey) => {
  const encryptedKey = EncryptUsingAES256(storageKey);
  localStorage.removeItem(encryptedKey);
};

/* <summary>
date: 06-04-2024
Name: AP
description: Check if string is empty/undefined/null
<summary>*/
export const isStringEmpty = (text) => {

  //try {
  return !text || text.trim().length === 0;
  // } catch (error) {
  //   console.log(error)
  // }
};

/* <summary>
date: 06-04-2024
Name: AP
description: Encryption using CryptoJS
<summary>*/
export const EncryptUsingAES256 = (text) => {
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
    iv,
    keySize: 128 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

/* <summary>
date: 06-04-2024
Name: AP
description: Decryption using CryptoJS
<summary>*/
export const DecryptUsingAES256 = (decString) => {
  const decrypted = CryptoJS.AES.decrypt(decString, key, {
    iv,
    keySize: 128 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};
/* <summary>
date: 06-04-2024
Name: AP
description: Email Validation
<summary>*/
export const IsEmailValid = (emailAddress) => {
  const emailRegexString =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  ///^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/; from other ag projects
  return emailRegexString.test(emailAddress);
};

/* <summary>
date: 06-04-2024
Name: AP
description: Validates password
<summary>*/
export const IsPasswordValid = (passowrd) => {
  const passwordRegexString = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
  return passwordRegexString.test(passowrd);
};
/* <summary>
date: 06-04-2024
Name: AP
description: Common fn calling archive service to add archive
<summary>*/
export const AddArchiveFunction = (actionNumber, data, userId, secondaryId) => {
  const payLoad = new ArchiveModel();
  payLoad.archiveaction = actionNumber;
  payLoad.archivedata = data;
  payLoad.userid = userId;
  payLoad.secondaryid = secondaryId;
  //payLoad.CreatedBy = userId;

  ArchiveService.AddArchive(payLoad);
};


//Allow only positive integers
//Value being passed shouldn't be blank
export const AllowOnlyIntegers = (value) => {
  const NUMBER_REGEX = /^[0-9\b]+$/;
  return NUMBER_REGEX.test(value)
}
//Allow only positive Decimals max 2 places
export const AllowPositiveDecimalNumbers = (value) => {
  const NUMBER_REGEX = /^[0-9]*(\.[0-9]{0,2})?$/;
  return NUMBER_REGEX.test(value)
}

/* <summary>
date: 07-06-2024
Name: AP
description: Sets the state for the date control
params: date: current date state value, value: used to update, datePart: D/M/Y
<summary>*/
export const SetDateState = (date, value, datePart) => {
  //debugger
  const parts = date?.split('/') ?? [];
  let month = parts[0] ?? "";
  let day = parts[1] ?? "";
  let year = parts[2] ?? "";

  // const parts = date.split('/');
  // let month = parts[0];
  // let day = parts[1];
  // let year = parts[2];

  if (datePart === DatePart.Day) {
    day = value;
  }
  else if (datePart === DatePart.Month) {
    month = value;
  }
  else if (datePart === DatePart.Year) {
    year = value;
  }

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}
/* <summary>
date: 07-06-2024
Name: AP
description: Handles UNK selection in date. Returns absolute date
<summary>*/
export const ParseDate = (dateStr) => {
  const parts = dateStr.split('/');
  let month = parts[0];
  let day = parts[1];
  const year = parts[2];

  if (day === "UNK" && month === "UNK") {
    day = "01";
    month = "07";
  } else if (day === "UNK") {
    day = "15";
  } else if (month === "UNK") {
    month = "06";
  }
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}
/* <summary>
date: 07-06-2024
Name: AP
description: Checks if date is empty or contains any default value in day month year dropdown
<summary>*/
export const IsDateEmpty = (date) => {
  return isStringEmpty(date) || date.includes("MM") || date.includes("DD") || date.includes("YYYY");
}
/* <summary>
date: 07-06-2024
Name: AP
description: Checks if date exists. If UNK is selected return true
<summary>*/
export const IsDateValid = (dateStr) => {
  //if UNK is present return true
  if (dateStr.includes("UNK")) {
    return true;
  }
  else {
    const date = moment(dateStr, "MM/DD/YYYY");
    return date.isValid();
  }
}

/* <summary>
date: 10-06-2024
Name: AP
description: Returns the Day Month Unknown id as per the presence of UNK value in the date
<summary>*/
export const GetDMUID = (dateStr) => {
  //debugger
  const parts = dateStr.split('/');
  let month = parts[0];
  let day = parts[1];

  if (day === "UNK" && month === "UNK") {
    return DayMonthUnknown.DayMonthUnk;
  } else if (day === "UNK") {
    return DayMonthUnknown.DayUnk;
  } else if (month === "UNK") {
    return DayMonthUnknown.MonthUnk;
  } else
    return null;
}
/* <summary>
date: 20-10-2023
Name: AP
description: Subtract months from date
<summary>*/
export const SubtractMonths = (dateStr, months) => {
  if (!dateStr) {
    return null;
  }
  const momentDate = moment(dateStr, "MM/DD/YYYY");
  if (!momentDate.isValid()) {
    return null;
  }
  return momentDate.subtract(months, 'months').toDate();
}
/* <summary>
date: 20-10-2023
Name: AP
description: Format date to display
<summary>*/
export const DateDisplayFormat = (date) => {
  const momentDate = moment(date);

  if (momentDate.isValid()) {
    const month = momentDate.format('MM');
    const day = momentDate.format('DD');
    const year = momentDate.format('YYYY');

    return `${month}/${day}/${year}`;
  } else {
    return false;
  }
}
/* <summary>
date: 10-06-2024
Name: AP
description: Render UNK selection in day month dropdowns
<summary>*/
export const GetDisplayDate = (dateStr, dmuId) => {
  if (IsDateEmpty(dateStr) || !dmuId) {
    return dateStr;
  }
  const parts = dateStr.split('/');
  let month = parts[0];
  let day = parts[1];
  const year = parts[2];

  if (dmuId === DayMonthUnknown.DayMonthUnk) {
    day = "UNK";
    month = "UNK";
  } else if (dmuId === DayMonthUnknown.MonthUnk) {
    month = "UNK";
  } else if (dmuId === DayMonthUnknown.DayUnk) {
    day = "UNK";
  }
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}

/* <summary>
date: 11-06-2024
Name: NN
description: Check if age greater than equal to 18
<summary>*/
export const IsAge18 = (dob, referenceDate) => {
  //debugger
  if (!dob || !referenceDate) {
    return false;
  }

  // Parse dates to handle "UNK" values
  const parsedDob = ParseDate(dob);
  const parsedReferenceDate = ParseDate(referenceDate);

  const birthDate = moment(parsedDob, "MM/DD/YYYY");
  const refDate = moment(parsedReferenceDate, "MM/DD/YYYY");

  if (!birthDate.isValid() || !refDate.isValid()) {
    return false;
  }

  const age = refDate.diff(birthDate, 'years', true); // The true parameter returns the difference as a float
  return age >= 18;
};



// export const IsDateCriteriaValid = (indexDate, lbclDxDate) => {
//   //debugger

//   // Check if the dates are provided
//   if (!indexDate || !lbclDxDate) {
//     return false;
//   }

//   // Parse dates to handle "UNK" values
//   const parsedIndexDate = ParseDate(indexDate);
//   const parsedLbclDxDate = ParseDate(lbclDxDate);

//   const specificDate = moment("05/19/2023", "MM/DD/YYYY");

//   const indexDateMoment = moment(parsedIndexDate, "MM/DD/YYYY");
//   const lbclDxDateMoment = moment(parsedLbclDxDate, "MM/DD/YYYY");

//   if (!indexDateMoment.isValid() || !lbclDxDateMoment.isValid()) {
//     return false;
//   }

//   // Check if the index date is on or after the LBCL diagnosis date
//   const isAfterLbclDxDate = indexDateMoment.isSameOrAfter(lbclDxDateMoment);

//   // Check if the index date is after 05/19/2023
//   const isAfterSpecificDate = indexDateMoment.isAfter(specificDate);

//   console.log("trueorfalse",isAfterLbclDxDate && isAfterSpecificDate);
//   return isAfterLbclDxDate && isAfterSpecificDate;
// };

/* <summary>
date: 12-06-2024
Name: NN
description:  Patient screening s2  criteria 1 before lbcl
<summary>*/

export const DateCriteria1 = (indexDate, lbclDxDate) => {
  //debugger

  // Check if the dates are provided
  if (!indexDate || !lbclDxDate) {
    return false;
  }

  // Parse dates to handle "UNK" values
  const parsedIndexDate = ParseDate(indexDate);
  const parsedLbclDxDate = ParseDate(lbclDxDate);

  // const specificDate = moment("05/19/2023", "MM/DD/YYYY");

  const indexDateMoment = moment(parsedIndexDate, "MM/DD/YYYY");
  const lbclDxDateMoment = moment(parsedLbclDxDate, "MM/DD/YYYY");

  if (!indexDateMoment.isValid() || !lbclDxDateMoment.isValid()) {
    return false;
  }

  // Check if the index date is on or after the LBCL diagnosis date
  const isAfterLbclDxDate = indexDateMoment.isSameOrAfter(lbclDxDateMoment);

  // // Check if the index date is after 05/19/2023
  // const isAfterSpecificDate = indexDateMoment.isAfter(specificDate);

  return isAfterLbclDxDate;
};

/* <summary>
date: 12-06-2024
Name: NN
description:  Patient screening s2  criteria 2 after 5/19/23
<summary>*/
export const DateCriteria2 = (indexDate) => {
  //debugger

  // Check if the dates are provided
  if (!indexDate) {
    return false;
  }

  // Parse dates to handle "UNK" values
  const parsedIndexDate = ParseDate(indexDate);

  const specificDate = moment("05/19/2023", "MM/DD/YYYY");

  const indexDateMoment = moment(parsedIndexDate, "MM/DD/YYYY");

  if (!indexDateMoment.isValid()) {
    return false;
  }

  // Check if the index date is after 05/19/2023
  const isAfterSpecificDate = indexDateMoment.isAfter(specificDate);

  return isAfterSpecificDate;
};



/* <summary>
date: 12-06-2024
Name: NN
description:  Demographics Q10 
<summary>*/

// export const IsValidDate = (dateToValidate, indexDate,chartAbstractionDate, diagnosisDate ) => {
//   //debugger;
//   // Check if any of the dates are not provided
//   if (!dateToValidate || !indexDate || !diagnosisDate || !chartAbstractionDate) {
//     return false;
//   }

//   // Parse dates to handle "UNK" values
//   const parsedDateToValidate = ParseDate(dateToValidate);
//   const parsedIndexDate = ParseDate(indexDate);
//   const parsedDiagnosisDate = ParseDate(diagnosisDate);
//   const parsedChartAbstractionDate = ParseDate(chartAbstractionDate);

//   // Create moment objects from the parsed dates
//   const dateMoment = moment(parsedDateToValidate, "MM/DD/YYYY");
//   const indexDateMoment = moment(parsedIndexDate, "MM/DD/YYYY");
//   const diagnosisDateMoment = moment(parsedDiagnosisDate, "MM/DD/YYYY");
//   const chartAbstractionDateMoment = moment(parsedChartAbstractionDate, "MM/DD/YYYY");

//   // Check if the dates are valid
//   if (!dateMoment.isValid() || !indexDateMoment.isValid() || !diagnosisDateMoment.isValid() || !chartAbstractionDateMoment.isValid()) {
//     return false;
//   }

//   // Check if the date to validate is on or after the index date
//   const isOnOrAfterIndexDate = dateMoment.isSameOrAfter(indexDateMoment);

//   // Check if the date to validate is on or after the diagnosis date
//   const isOnOrAfterDiagnosisDate = dateMoment.isSameOrAfter(diagnosisDateMoment);

//   // Check if the date to validate is on or before the chart abstraction date
//   const isOnOrBeforeChartAbstractionDate = dateMoment.isSameOrBefore(chartAbstractionDateMoment);

//   // Return true if all criteria are met
//   return isOnOrAfterIndexDate && isOnOrAfterDiagnosisDate && isOnOrBeforeChartAbstractionDate;
// };




/* <summary>
date: 13-06-2024
Name: NN
description:  Demographics Q10  criteria 1 
<summary>*/


export const IsValidDate1 = (dateToValidate, indexDate) => {
  //debugger;
  // Check if any of the dates are not provided
  if (!dateToValidate) {
    return false;
  }

  // Parse dates to handle "UNK" values
  const parsedDateToValidate = ParseDate(dateToValidate);
  const parsedIndexDate = ParseDate(indexDate);


  // Create moment objects from the parsed dates
  const dateMoment = moment(parsedDateToValidate, "MM/DD/YYYY");
  const indexDateMoment = moment(parsedIndexDate, "MM/DD/YYYY");

  // Check if the dates are valid
  if (!dateMoment.isValid() || !indexDateMoment.isValid()) {
    return false;
  }

  // Check if the date to validate is on or after the index date
  const isOnOrAfterIndexDate = dateMoment.isSameOrAfter(indexDateMoment);


  // Return true if all criteria are met
  return isOnOrAfterIndexDate
};


/* <summary>
date: 13-06-2024
Name: NN
description:  Demographics Q10  criteria 2
<summary>*/


export const IsValidDate2 = (dateToValidate, diagnosisDate) => {
  //debugger;
  // Check if any of the dates are not provided
  if (!dateToValidate || !diagnosisDate) {
    return false;
  }

  // Parse dates to handle "UNK" values
  const parsedDateToValidate = ParseDate(dateToValidate);
  const parsedDiagnosisDate = ParseDate(diagnosisDate);


  // Create moment objects from the parsed dates
  const dateMoment = moment(parsedDateToValidate, "MM/DD/YYYY");
  const diagnosisDateMoment = moment(parsedDiagnosisDate, "MM/DD/YYYY");

  // Check if the dates are valid
  if (!dateMoment.isValid() || !diagnosisDateMoment.isValid()) {
    return false;
  }


  // Check if the date to validate is on or after the diagnosis date
  const isOnOrAfterDiagnosisDate = dateMoment.isSameOrAfter(diagnosisDateMoment);



  // Return true if all criteria are met
  return isOnOrAfterDiagnosisDate;
};







/* <summary>
date: 13-06-2024
Name: NN
description:  Demographics Q10  criteria 3
<summary>*/

export const IsValidDate3 = (dateToValidate, chartAbstractionDate) => {
  //debugger;
  // Check if any of the dates are not provided
  if (!dateToValidate || !chartAbstractionDate) {
    return false;
  }

  // Parse dates to handle "UNK" values
  const parsedDateToValidate = ParseDate(dateToValidate);
  const parsedChartAbstractionDate = ParseDate(chartAbstractionDate);

  // Create moment objects from the parsed dates
  const dateMoment = moment(parsedDateToValidate, "MM/DD/YYYY");
  const chartAbstractionDateMoment = moment(parsedChartAbstractionDate, "MM/DD/YYYY");

  // Check if the dates are valid
  if (!dateMoment.isValid() || !chartAbstractionDateMoment.isValid()) {
    return false;
  }


  // Check if the date to validate is on or before the chart abstraction date
  const isOnOrBeforeChartAbstractionDate = dateMoment.isSameOrBefore(chartAbstractionDateMoment);

  // Return true if all criteria are met
  return isOnOrBeforeChartAbstractionDate;
};










/* <summary>
date: 12-06-2024
Name: NN
description:  Demographics Q8 criteria 1 
<summary>*/

// export const q8ValidDate = (dateToValidate, indexDate, chartAbstractionDate) => {
//   //debugger;
//   // Check if any of the dates are not provided
//   if (!dateToValidate || !indexDate || !chartAbstractionDate) {
//     return false;
//   }

//   // Parse dates to handle "UNK" values
//   const parsedDateToValidate = ParseDate(dateToValidate);
//   const parsedIndexDate = ParseDate(indexDate);
//   const parsedChartAbstractionDate = ParseDate(chartAbstractionDate);

//   // Create moment objects from the parsed dates
//   const dateMoment = moment(parsedDateToValidate, "MM/DD/YYYY");
//   const indexDateMoment = moment(parsedIndexDate, "MM/DD/YYYY");
//   const chartAbstractionDateMoment = moment(parsedChartAbstractionDate, "MM/DD/YYYY");

//   // Check if the dates are valid
//   if (!dateMoment.isValid() || !indexDateMoment.isValid() || !chartAbstractionDateMoment.isValid()) {
//     return false;
//   }

//   // Check if the date meets the criteria
//   const isOnOrAfterIndexDate = dateMoment.isSameOrAfter(indexDateMoment);
//   const isOnOrBeforeChartAbstractionDate = dateMoment.isSameOrBefore(chartAbstractionDateMoment);

//   // Return true if all criteria are met
//   return isOnOrAfterIndexDate && isOnOrBeforeChartAbstractionDate;
// };

export const q8ValidDate1 = (dateToValidate, indexDate) => {
  //debugger;
  // Check if any of the dates are not provided
  if (!dateToValidate || !indexDate) {
    return false;
  }

  // Parse dates to handle "UNK" values
  const parsedDateToValidate = ParseDate(dateToValidate);
  const parsedIndexDate = ParseDate(indexDate);

  // Create moment objects from the parsed dates
  const dateMoment = moment(parsedDateToValidate, "MM/DD/YYYY");
  const indexDateMoment = moment(parsedIndexDate, "MM/DD/YYYY");

  // Check if the dates are valid
  if (!dateMoment.isValid() || !indexDateMoment.isValid()) {
    return false;
  }

  // Check if the date meets the criteria
  const isOnOrAfterIndexDate = dateMoment.isSameOrAfter(indexDateMoment);


  // Return true if all criteria are met
  return isOnOrAfterIndexDate;
};

/* <summary>
date: 12-06-2024
Name: NN
description:  Demographics Q8 criteria 2 
<summary>*/


export const q8ValidDate2 = (dateToValidate, chartAbstractionDate) => {
  //debugger;
  // Check if any of the dates are not provided
  if (!dateToValidate || !chartAbstractionDate) {
    return false;
  }

  // Parse dates to handle "UNK" values
  const parsedDateToValidate = ParseDate(dateToValidate);
  const parsedChartAbstractionDate = ParseDate(chartAbstractionDate);

  // Create moment objects from the parsed dates
  const dateMoment = moment(parsedDateToValidate, "MM/DD/YYYY");
  const chartAbstractionDateMoment = moment(parsedChartAbstractionDate, "MM/DD/YYYY");

  // Check if the dates are valid
  if (!dateMoment.isValid() || !chartAbstractionDateMoment.isValid()) {
    return false;
  }

  // Check if the date meets the criteria
  const isOnOrBeforeChartAbstractionDate = dateMoment.isSameOrBefore(chartAbstractionDateMoment);

  // Return true if all criteria are met
  return isOnOrBeforeChartAbstractionDate;
};


/* <summary>
date: 11-06-2024
Name: AP
description: Gets the ordinal value of a number
<summary>*/
export const GetOrdinal = (n) => {
  let suffix;
  const lastDigit = n % 10;
  const lastTwoDigits = n % 100;
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    suffix = "th";
  } else {
    switch (lastDigit) {
      case 1:
        suffix = "st";
        break;
      case 2:
        suffix = "nd";
        break;
      case 3:
        suffix = "rd";
        break;
      default:
        suffix = "th";
        break;
    }
  }
  return <span>{n}<sup>{suffix}</sup></span>;
};

/* <summary>
date: 11-06-2024
Name: AP
description: Gets the ordinal value of a number in text format
<summary>*/
export const GetOrdinalInWord = (num) => {
  const ordinals = [
    "first", "second", "third", "fourth",
    "fifth", "sixth", "seventh", "eighth", "ninth", "tenth"
  ];
  if (num > 0 && num < 11) {
    return ordinals[num - 1];
  } else {
    return `${num}th`;
  }
};

export const ValueInRange = (value, lowLim, highLim) => {
  return value >= lowLim && value <= highLim;
}




/* <summary>
date: 18-06-2024
Name: NN
description: Date after Index date check
<summary>*/


export const IsAfterIndex = (dateToValidate, indexDate) => {
  //debugger;
  // Check if any of the dates are not provided
  if (!dateToValidate) {
    return false;
  }

  // Parse dates to handle "UNK" values
  const parsedDateToValidate = ParseDate(dateToValidate);
  const parsedIndexDate = ParseDate(indexDate);


  // Create moment objects from the parsed dates
  const dateMoment = moment(parsedDateToValidate, "MM/DD/YYYY");
  const indexDateMoment = moment(parsedIndexDate, "MM/DD/YYYY");

  // Check if the dates are valid
  if (!dateMoment.isValid() || !indexDateMoment.isValid()) {
    return false;
  }

  // Check if the date to validate is on or after the index date
  const isOnOrAfterIndexDate = dateMoment.isAfter(indexDateMoment);


  // Return true if all criteria are met
  return isOnOrAfterIndexDate
};



/* <summary>
date: 18-06-2024
Name: NN
description: Date  before the earlier of either date of death or date of chart abstraction
<summary>*/


// export const IsDateBefore = (dtDiscont, chartAbstractionDate, deathDate) => {
//   // Check if the discontinuation date or chart abstraction date is not provided
//   if (!dtDiscont || !chartAbstractionDate) {
//     return false;
//   }

//   // Parse dates to handle "UNK" values or invalid dates
//   const parsedDiscontDate = ParseDate(dtDiscont);
//   const parsedChartAbstractionDate = ParseDate(chartAbstractionDate);
//   const parsedDeathDate = deathDate ? ParseDate(deathDate) : null;

//   // Create moment objects from the parsed dates
//   const discontMoment = moment(parsedDiscontDate, "MM/DD/YYYY");
//   const chartAbstractionMoment = moment(parsedChartAbstractionDate, "MM/DD/YYYY");
//   const deathMoment = parsedDeathDate ? moment(parsedDeathDate, "MM/DD/YYYY") : null;

//   // Check if the dates are valid
//   if (!discontMoment.isValid() || !chartAbstractionMoment.isValid() || !deathMoment.isValid()) {
//     return false;
//   }

//   // Determine the earlier of the death date and the chart abstraction date
//   let earlierDateMoment = chartAbstractionMoment;
//   if (deathMoment && deathMoment.isValid()) {
//     earlierDateMoment = moment.min(chartAbstractionMoment, deathMoment);
//   }

//   // Check if the discontinuation date is before the earlier date
//   const isBeforeEarlierDate = discontMoment.isBefore(earlierDateMoment);

//   // Return true if all criteria are met
//   return isBeforeEarlierDate;
// };

 export const IsDateBefore = (dtDiscont, chartAbstractionDate, deathDate) => {
  // Check if the discontinuation date is not provided
  if (!dtDiscont) {
    return false;
  }

  // Parse the discontinuation date
  const parsedDiscontDate = ParseDate(dtDiscont);
  const discontMoment = moment(parsedDiscontDate, "MM/DD/YYYY");

  // If both deathDate and chartAbstractionDate are empty, return false
  if (!deathDate && !chartAbstractionDate) {
    return false;
  }

  // Parse and handle the earlier date between deathDate and chartAbstractionDate
  let earlierDateMoment = null;
  if (deathDate && chartAbstractionDate) {
    const parsedChartAbstractionDate = ParseDate(chartAbstractionDate);
    const parsedDeathDate = ParseDate(deathDate);

    const chartAbstractionMoment = moment(parsedChartAbstractionDate, "MM/DD/YYYY");
    const deathMoment = moment(parsedDeathDate, "MM/DD/YYYY");

    if (chartAbstractionMoment.isValid() && deathMoment.isValid()) {
      earlierDateMoment = moment.min(chartAbstractionMoment, deathMoment);
    } else if (chartAbstractionMoment.isValid()) {
      earlierDateMoment = chartAbstractionMoment;
    } else if (deathMoment.isValid()) {
      earlierDateMoment = deathMoment;
    }
  } else if (chartAbstractionDate) {
    const parsedChartAbstractionDate = ParseDate(chartAbstractionDate);
    const chartAbstractionMoment = moment(parsedChartAbstractionDate, "MM/DD/YYYY");
    if (chartAbstractionMoment.isValid()) {
      earlierDateMoment = chartAbstractionMoment;
    }
  } else if (deathDate) {
    const parsedDeathDate = ParseDate(deathDate);
    const deathMoment = moment(parsedDeathDate, "MM/DD/YYYY");
    if (deathMoment.isValid()) {
      earlierDateMoment = deathMoment;
    }
  }

  // Check if the discontinuation date is before the earlier date
  const isBeforeEarlierDate = earlierDateMoment && discontMoment.isValid() && discontMoment.isBefore(earlierDateMoment);

  return isBeforeEarlierDate;
};



/* <summary>
date: 21-06-2024
Name: NN
description: Date after Index date check
<summary>*/


export const Is_DateBefore = (dateToValidate, date) => {
  //debugger;
  // Check if any of the dates are not provided
  if (!dateToValidate ) {
    return false;
  }

  // Parse dates to handle "UNK" values
  const parsedDateToValidate = ParseDate(dateToValidate);
  const parsedIndexDate = ParseDate(date);


  // Create moment objects from the parsed dates
  const dateMoment = moment(parsedDateToValidate, "MM/DD/YYYY");
  const validateDateMoment = moment(parsedIndexDate, "MM/DD/YYYY");

  // Check if the dates are valid
  if (!dateMoment.isValid() || !validateDateMoment.isValid() ) {
    return false;
  }

  // Check if the date to validate is on or after the index date
  const isBeforeIndexDate = dateMoment.isBefore(validateDateMoment);
  

  // Return true if all criteria are met
  return isBeforeIndexDate 
};

// Function to display selected items as comma-separated text
// list of selected checkboxes and their associated text
export const DisplaySelectedText = (list) => {
  const sortedKeys = Array.from(list.keys()).sort((a, b) => a - b);  
  const orderedValues = sortedKeys.map(key => list.get(key));
  return orderedValues.join(', '); 
}; 



/* <summary>
date: 26-06-2024
Name: NN
description: Date after ref date check
<summary>*/


export const IsOnOrAfterDate= (dateToValidate, refDate) => {
  // debugger;
  // Check if any of the dates are not provided
  if (!dateToValidate) {
    return false;
  }

  // Parse dates to handle "UNK" values
  const parsedDateToValidate = ParseDate(dateToValidate);
  const parsedRefDate = ParseDate(refDate);


  // Create moment objects from the parsed dates
  const dateMoment = moment(parsedDateToValidate, "MM/DD/YYYY");
  const refDateMoment = moment(parsedRefDate, "MM/DD/YYYY");

  // Check if the dates are valid
  if (!dateMoment.isValid() || !refDateMoment.isValid()) {
    return false;
  }

  // Check if the date to validate is on or after the ref date
  const isOnOrAfterRefDate = dateMoment.isSameOrAfter(refDateMoment);


  // Return true if all criteria are met
  return isOnOrAfterRefDate
};

//Function to set Hard and Soft Error Messages on Demographics page
export function setErrorList(errorList) {
  errorList.forEach(({ setterFn, updates }) => {
    setterFn((prev) => ({
      ...prev,
      ...updates,
    }));
  });
}
