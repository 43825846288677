import React, { useContext, useEffect, useState } from 'react';
import { SideMenuItems, SideMenuPageList, HTTPResponse, ToastMessageType } from '../Helpers/Enums';
import { useNavigate } from 'react-router';
import { AddLocalStorageData, GetLocalStorageData, isStringEmpty } from '../Helpers/Utilities';
import { PatientService } from "../WebApiServices/Patient.service";
import { AppContext } from '../Contexts/AppContextProvider';


const SideBarMenu = (props) => {

    const [incompletePageIds, setIncompletePageIds] = useState([]);
    const activePageId = props.activePageId;
    const patientID = GetLocalStorageData("patientId") ?? 0;
    const navigate = useNavigate();
    const { ShowToast, ToggleLoader, HandleSessionTimeout, appState
        , SetTmtPriorEpcoLOTCnt, SetTmtAfterEpcoLOTCnt } = useContext(AppContext);
    //const { isSubmitted, formIncomplete } = useContext(AppContext);
    let patientData = GetLocalStorageData("patient");
    patientData = patientData && JSON.parse(patientData);

    const submit = patientData?.submit ?? 0;
    const locked = patientData?.locked ?? false;
    // const pid = patientData?.patientId ?? 0;
    // const userId = GetLocalStorageData("userId") ?? 0;

    const [pageList, setPageList] = useState(SideMenuPageList);

    const loadConfig = GetLocalStorageData("loadConfig") ?? false;
    const [eligible, setEligible] = useState(false);
    const [loading, setLoading] = useState(true);//https://ideawharf.sifterapp.com/issues/11480

    const initialSideMenuPageList = [
        { title: 
            <span style={{ color: '#27cced', display: 'inline' }}>
                ELIGIBILITY CRITERIA AND PATIENT SCREENING
            </span>
            , 
            notClickable: true },
        { id: 1, title: "ELIGIBILITY CRITERIA", path: "/EligibilityCriteria", hasParent: true }, 
        { id: 2, title: "PATIENT SCREENING", path: "/PatientScreening", hasParent: true },
        { id: 3, title: "DEMOGRAPHIC CHARACTERISTICS", path: "/Demographics", incomplete: false },
        { id: 4, title: "CLINICAL CHARACTERISTICS", path: "/ClinicalCharacteristics", incomplete: false },
      
        { title: 
            <span style={{ color: '#27cced', display: 'inline' }}>
                TREATMENT AND RESPONSE
            </span>
            , notClickable: true },
        { id: 5, title: "EPCORITAMAB TREATMENT", path: "/Epcoritamab", hasParent: true, incomplete: false },
        { id: 6, title: 
            <span style={{ color: '#27cced', display: 'inline' }}>
                TREATMENTS PRIOR TO INITIATING EPCORITAMAB
            </span>, 
            path: "/TreatmentsPriorEpcoritamab", hasParent: true, notClickable: true },
        { id: 7, title: 
            <span style={{ color: '#27cced', display: 'inline' }}>
                TREATMENTS AFTER INITIATING EPCORITAMAB
            </span>
            , path: "/TreatmentsAfterEpcoritamab", hasParent: true, notClickable: true },
        { id: 8, title: "INTENTION TO TREAT WITH CAR T CELL THERAPY", path: "/CARTCellTherapy", hasParent: true, incomplete: false },
        { id: 9, title: "HEALTHCARE RESOURCE UTILIZATION", path: "/HRU", incomplete: false },
        { id: 10, title: "VALIDATE & SUBMIT", path: "/ValidateSubmit"}
      ];
      



    useEffect(() => {
        const FetchData = async () => {

            try {
                AddLocalStorageData("loadConfig", false);

                //ToggleLoader(true);
                const response1 = await PatientService.IsEligiblePatient(patientID);
                const isEligible = response1?.data ?? false;
                setEligible(isEligible)
                if (response1?.status === HTTPResponse.OK) {

                    const response2 = await PatientService.GetPatientConfigData(patientID);
                    if (response2?.status === HTTPResponse.OK) {
                        if (response2 != null) {
                            const { data } = response2;
                            if (data != null) {
                                const { item1, item2, item3 } = data;
                                //item1 => patient config static pages data 
                                //item2 => tmt prior epco lot config data   
                                //item3  => tmt after epco lot config data   
                                            // Reset SideMenuPageList before cloning
            let SideMenuPageList = [...initialSideMenuPageList];
            let listClone = [...SideMenuPageList];        
                                // let listClone = [...SideMenuPageList]                                
                                //let listClone2 =  [...SideMenuPageList]
                                
                                if (item1 != null && item1.length > 0) {
                                    item1.forEach(a => {
                                        // Find the corresponding object in listClone by pageId
                                        let matchObj = listClone.find(obj => obj.id === a.pageId);

                                        // If a matching object is found, update its incomplete property
                                        if (matchObj) {
                                            matchObj.incomplete = a.incomplete;
                                        }
                                    })
                                } 
                                let listBeforeTmtPrior = listClone.slice(0, SideMenuItems.TreatmentsPriorEpcoritamab + 1);//1,2,3,4,5
                                let listTmtPriorLots = [];
                                let listTmtAfterLots = [];
                                let listAfterTmtAfter = listClone.slice(SideMenuItems.CarTCellTherapy + 1);//8,9,10
                                
                                SetTmtPriorEpcoLOTCnt(0);
                                if (item2 != null && item2.length > 0) {

                                    SetTmtPriorEpcoLOTCnt(item2.length);

                                    listTmtPriorLots.push(listClone.find(a => a.id === SideMenuItems.TreatmentsPriorEpcoritamab));//header

                                    item2.map((a, i) => {
                                        //const lotNum = a.lotNumber;
                                        const lotItem = {
                                            id: a.pageId,//SideMenuItems.TreatmentsPriorEpcoritamab + lotNum,
                                            title: `LOT ${i + 1}`,
                                            path: "/TreatmentsPriorEpcoritamab",
                                            lotNum: i + 1,
                                            hasGrandParent: true,
                                            incomplete: a.incomplete,
                                            disabled: a.disabled,
                                        };
                                        listTmtPriorLots.push(lotItem);
                                    })
                                }

                                SetTmtAfterEpcoLOTCnt(0);
                                if (item3 != null && item3.length > 0) {
                                    SetTmtAfterEpcoLOTCnt(item3.length);
                                    listTmtAfterLots.push(listClone.find(a => a.id === SideMenuItems.TreatmentsAfterEpcoritamab));//header

                                    item3.map((a, i) => {
                                        //const lotNum = a.lotNumber;
                                        const lotItem = {
                                            id: a.pageId,//SideMenuItems.TreatmentsPriorEpcoritamab + lotNum,
                                            title: `LOT ${i + 1}`,
                                            path: "/TreatmentsAfterEpcoritamab",
                                            lotNum: i + 1,
                                            hasGrandParent: true,
                                            incomplete: a.incomplete
                                        };
                                        listTmtAfterLots.push(lotItem);
                                    })
                                }

                                if(!appState.showCartPage){
                                    listAfterTmtAfter = listAfterTmtAfter.filter(a=>a.id !== SideMenuItems.CarTCellTherapy);
                                }
                                const finalList = [
                                    ...listBeforeTmtPrior,
                                    ...listTmtPriorLots,
                                    ...listTmtAfterLots,
                                    ...listAfterTmtAfter
                                ];

                                setPageList(finalList);
                            }
                        }
                        //ToggleLoader(false);
                    }
                    else if (response1?.status === HTTPResponse.Unauthorized) {
                        HandleSessionTimeout();
                    }
                }
                else if (response1?.status === HTTPResponse.Unauthorized) {
                    HandleSessionTimeout();
                }
            } catch (e) {
                //ToggleLoader(false);
            }
            finally {
                setLoading(false);
            }
        }

        FetchData();
    }, [loadConfig, appState.showCartPage ])//, appState.showCartPage 


    /* <summary>
    date: 04-11-2023
    Name: AP
    description: Handle Navigation with Highlight on Validate/Submit Click.
      Edited the function. Removed highlighting of incomplete forms 
        inside a useEffect since it wasn't showing up on first click.
    <summary>*/
    const HandleNavigate = async (pageUrl, lotNum) => {

        try {

            if (patientID > 0) {
                //ToggleLoader(true);
                const response = await PatientService.IsEligiblePatient(patientID);
                const isEligible = response?.data ?? false;


                const ptArr = { ...patientData }
                AddLocalStorageData("patient", JSON.stringify(ptArr))

                if (isEligible) {
                    if (pageUrl === "/TreatmentsPriorEpcoritamab" || pageUrl === "/TreatmentsAfterEpcoritamab") {
                        //navigate(pageUrl, { state: { lot: lotNum } });         
                        navigate(pageUrl + "/" + lotNum)
                    }
                    else {
                        navigate(pageUrl);
                    }
                }
                else {
                    if (pageUrl.trim().includes("PatientScreening") || pageUrl.trim().includes("EligibilityCriteria")) {
                        navigate(pageUrl);
                    }
                }
                //ToggleLoader(false)
            }
            else{
                navigate(pageUrl);
            }
        } catch {

        }
    }
    return (
        <div className="col-md-3 col-pad-right">
            <div className="tab tab-custom">
                <div className="activity-link-tab">
                    {!loading && (
                        pageList?.map((item) => (
                            <button key={item.id}
                                onClick={() => { HandleNavigate(item.path, item.lotNum) }}
                                className={`tablinks ${activePageId == item.id && "active"} 
                ${!item.notClickable && "clickable"}`}
                                style={{ color: item.incomplete ? "red " : "white" }}
                                disabled={item.notClickable || (((patientID === 0 || !eligible) && item.id > 2) || item.disabled)}>
                                <span className={`${item.hasParent && "child"} ${item.hasGrandParent && "grandchild"}`}
                                >
                                    {!loading && (
                                       !item.notClickable && ((item.id > 2 && (patientID === 0 || !eligible)) || item.disabled)
                                       && <img className="lock-icon" src="../Assets/images/locked.png" alt="lock" />
                                    )}

                                    {item.id !== SideMenuItems.ValidateSubmit
                                        ? <span>{item.title}</span>
                                        : (<span >
                                            {locked ? "SUBMITTED"
                                                // : submit === 1 && incompletePageIds.length === 0
                                                //   ? "Submit Patient" : item.title
                                                : item.title
                                            }
                                        </span>)
                                    }
                                </span>
                            </button>
                        ))
                    )}
                </div>
            </div>
        </div>
    )
}

export default SideBarMenu