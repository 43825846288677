import { DefaultDate } from "../Helpers/Enums";
 
export default class LabRowModel {
    constructor({LabTestTypeId, LabResultsId, CollectionDateSelect, CollectionDate, AssessSelect, AssessTxt,
        UpperLimitSelect, UpperLimitTxt, LowerLimitSelect, LowerLimitTxt, UnitSelect, UnitTxt, UnitTxtId,
        CollectDtUnkSoft, CollectDtEmptyHard,
        AssessUnkSoft, AssessEmptyHard, AssessTxtEmptyHard, AssessRangeLimitHard,
        UpperLimUnkSoft, UpperLimEmptyHard, UpperLimTxtEmptyHard, UpperLimRangeLimitHard,
        LowerLimUnkSoft, LowerLimEmptyHard, LowerLimTxtEmptyHard, LowerLimRangeLimitHard,
        UnitUnkSoft, UnitEmptyHard, UnitTxtEmptyHard}) {
 
        this.labResultsId = LabResultsId || 0;
        this.labTestTypeId = LabTestTypeId || 0;
        this.collectionDateSelect = CollectionDateSelect || null;
        this.collectionDate = CollectionDate || DefaultDate;
        this.assessSelect = AssessSelect || null;
        this.assessTxt = AssessTxt || "";
        this.upperLimitSelect = UpperLimitSelect || null;
        this.upperLimitTxt = UpperLimitTxt || "";
        this.lowerLimitSelect = LowerLimitSelect || null;
        this.lowerLimitTxt = LowerLimitTxt || "";
        this.unitSelect = UnitSelect || null;
        this.unitTxt = UnitTxt || "";
        this.unitTxtId = UnitTxtId || null;
        //error
        this.collectDtUnkSoft = CollectDtUnkSoft || false;
        this.collectDtEmptyHard = CollectDtEmptyHard || false;
        this.assessUnkSoft = AssessUnkSoft || false;
        this.assessEmptyHard = AssessEmptyHard || false;
        this.assessTxtEmptyHard = AssessTxtEmptyHard || false;
        this.assessRangeLimitHard = AssessRangeLimitHard || false;
 
        this.upperLimUnkSoft = UpperLimUnkSoft || false;
        this.upperLimEmptyHard = UpperLimEmptyHard || false;
        this.upperLimTxtEmptyHard = UpperLimTxtEmptyHard || false;
        this.upperLimRangeLimitHard = UpperLimRangeLimitHard || false;
 
        this.lowerLimUnkSoft = LowerLimUnkSoft || false;
        this.lowerLimEmptyHard = LowerLimEmptyHard || false;
        this.lowerLimTxtEmptyHard = LowerLimTxtEmptyHard || false;
        this.lowerLimRangeLimitHard = LowerLimRangeLimitHard || false;
 
        this.unitUnkSoft = UnitUnkSoft || false;
        this.unitEmptyHard = UnitEmptyHard || false;
        this.unitTxtEmptyHard = UnitTxtEmptyHard || false;
    }
}