import React from "react";

const Header = () => {
  return (

    <section className="header-section">
      <div className="container">
        <h5>
          REAL-WORLD OUTCOMES IN PATIENTS WITH RELAPSED OR REFRACTORY LARGE B-CELL LYMPHOMA (R/R LBCL) TREATED WITH
        </h5>
        <h5>EPCORITAMAB</h5>
        <h4>CASE REPORT FORM (CRF)</h4>
      </div>
    </section>
  );
};

export default Header;
