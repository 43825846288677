// import React from 'react';
// import { Modal, Button } from 'react-bootstrap';

// const InclusionCriteriaModal = ({ show, handleClose }) => {
//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>Inclusion Criteria</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="survey-introduction-section">
//           <div className="survey-introduction-content">
//             <div className="instructions"></div>
//             <div className="note-border-box">
//               <div className="important-note">
//                 <div>
//                   <h5>
//                     Patients must meet <u>all</u> the following inclusion
//                     criteria to be eligible for inclusion:
//                   </h5>
//                   <ul>
//                     <li>Adult patients (≥18 years of age)</li>
//                     <li>
//                       Documented CD20+ mature B-cell neoplasm with the
//                       following LBCL subtypes by World Health Organization
//                       (WHO) classification 2016 or 2008, based
//                       representative biopsy report as abstracted from the
//                       patient medical chart and reviewed by clinicians:
//                       <ul>
//                         <li>
//                           Diffuse Large B-Cell Lymphoma (DLBCL; de novo or
//                           histologically transformed from all indolent
//                           subtypes, including Richter’s transformation),
//                           including “double-hit” or “triple-hit” DLBCL
//                           with MYC and BCL2 and/or BCL6 translocations
//                           (technically classified in WHO 2016 as
//                           High-grade B-cell Lymphoma [HGBCL])
//                         </li>
//                         <li>HGBCL</li>
//                         <li>
//                           Other subtypes (e.g., Primary Mediastinal Large
//                           B-cell Lymphoma [PMBCL], Grade 3b Follicular
//                           Lymphoma (FL)]
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       R/R disease
//                       <ul>
//                         <li>
//                           Relapsed disease is defined as disease that has
//                           recurred ≥6 months after completion of therapy
//                         </li>
//                         <li>
//                           Refractory disease is defined as disease that
//                           either progressed during therapy or progressed
//                           &lt;6 months since completion of therapy
//                         </li>
//                       </ul>
//                     </li>
//                     <li>
//                       Treated with epcoritamab outside of a clinical trial
//                       setting
//                     </li>
//                   </ul>
//                   <br />
//                   <h5>Dates</h5>
//                   <p>
//                     Drop downs will be provided for you to enter dates. If
//                     the exact date is unknown, please select “UNK” for the
//                     unknown part of the date (day and/or month){" "}
//                   </p>
//                 </div>
//                 <br />
//               </div>
//             </div>
//             <br />
//           </div>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default InclusionCriteriaModal;
import React from 'react';
import { Modal, Button } from 'react-bootstrap';


const InclusionCriteriaModal = ({ show, handleClose }) => {
  return (
    <Modal className='modal-content-inclusion' show={show} onHide={handleClose} >
     
          <div className="modal-header">
            {/* <h5 className="modal-title">Inclusion Criteria</h5> */}
            <Button variant="close" onClick={handleClose} aria-label="Close" />
          </div>
          <div className="modal-body modal-inclusion">
            <div className="survey-introduction-section">
              <div className="survey-introduction-content">
                <div className="instructions"></div>
                <div className="note-border-box">
                  <div className="important-note">
                    <div>
                      <h5>
                        Patients must meet <u>all</u> the following inclusion
                        criteria to be eligible for inclusion:
                      </h5>
                      <ul>
                        <li>Adult patients (≥18 years of age)</li>
                        <li>
                          Documented CD20+ mature B-cell neoplasm with the
                          following LBCL subtypes by World Health Organization
                          (WHO) classification 2016 or 2008, based
                          representative biopsy report as abstracted from the
                          patient medical chart and reviewed by clinicians:
                          <ul>
                            <li>
                              Diffuse Large B-Cell Lymphoma (DLBCL; de novo or
                              histologically transformed from all indolent
                              subtypes, including Richter’s transformation),
                              including “double-hit” or “triple-hit” DLBCL
                              with MYC and BCL2 and/or BCL6 translocations
                              (technically classified in WHO 2016 as
                              High-grade B-cell Lymphoma [HGBCL])
                            </li>
                            <li>HGBCL</li>
                            <li>
                              Other subtypes (e.g., Primary Mediastinal Large
                              B-cell Lymphoma [PMBCL], Grade 3b Follicular
                              Lymphoma (FL)]
                            </li>
                          </ul>
                        </li>
                        <li>
                          R/R disease
                          <ul>
                            <li>
                              Relapsed disease is defined as disease that has
                              recurred ≥6 months after completion of therapy
                            </li>
                            <li>
                              Refractory disease is defined as disease that
                              either progressed during therapy or progressed
                              &lt;6 months since completion of therapy
                            </li>
                          </ul>
                        </li>
                        <li>
                          Treated with epcoritamab outside of a clinical trial
                          setting
                        </li>
                      </ul>
    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </div>
  
    </Modal>
  );
};

export default InclusionCriteriaModal;
