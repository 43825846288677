import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import {
  DefaultDate,
  HTTPResponse,
  PageDesignConstants,
  SideMenuItems,
  ToastMessageType,
  ToastMessages,
} from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import { Dropdown } from "react-bootstrap";
import ErrorField from "../../Components/ErrorField";
import {
  CommonError_Msgs,
  CommonMsgs,
  Demographic_Msgs,
  demographicScreen_Msgs,
} from "../../Helpers/HelperText";
import { AppContext } from "../../Contexts/AppContextProvider";
import {
  AllowOnlyDecimal,
  AllowOnlyIntegers,
  AllowPositiveDecimalNumbers,
  GetDMUID,
  GetDisplayDate,
  GetLocalStorageData,
  GetLoggedInUserID,
  IsDateEmpty,
  IsDateValid,
  IsValidDate,
  IsValidDate1,
  IsValidDate2,
  IsValidDate3,
  ParseDate,
  SetDateState,
  isStringEmpty,
  q8ValidDate,
  q8ValidDate1,
  q8ValidDate2,
  setErrorList,
} from "../../Helpers/Utilities";
import DateControl from "../../Components/DateControl";
import { PatientDemographicsService } from "../../WebApiServices/PatientDemographics.service";
import { PatientService } from "../../WebApiServices/Patient.service";
//import DateDropdown from 'react-date-dropdown';

const Demographics = () => {
  let patientData = GetLocalStorageData("patient");
  patientData = patientData && JSON.parse(patientData);
  const uniqueEncryptedPatientId = patientData?.uniqueEncryptedPatientId ?? 0;
  const surveyDate = patientData?.surveyDate ?? "";
  const userId = GetLoggedInUserID() ?? 0;



  const [selectedHeight, setSelectedHeight] = useState(null);
  const [heightCentimeter, setHeightCentimeters] = useState(false);
  const [heightInch, setHeightInches] = useState(false);

  const [selectedWeight, setSelectedWeight] = useState(null);
  const [weightKilograms, setWeightKilogram] = useState(false);
  const [weightPounds, setWeightPounds] = useState(false);




  const handleHeightChange = (unit) => {
    setSelectedHeight(unit);
    setPtDemographicsDetails((prev) => ({
      ...prev,
      q4Height: 1,
    }));
    if (unit === "centimeters") {
      setHeightCentimeters(true);
      setHeightInches(false);
    
    } else if (unit === "inches") {
      setHeightCentimeters(false);
      setHeightInches(true);
    }
    setErrorList([
      {
        setterFn: setHardErrList,
        updates: {
          q4Empty: false,
        },
      },
      {
        setterFn: setSoftErrList,
        updates: {
          q4Unk_err: false,
        },
      },
    ]);
    
  };

  const handleWeightChange = (unit) => {
    setSelectedWeight(unit);
    setPtDemographicsDetails((prev) => ({
      ...prev,
      q5Weight: 1,
    }));
    if (unit === "kilograms") {
      setWeightKilogram(true);
      setWeightPounds(false);
    } else if (unit === "pounds") {
      setWeightKilogram(false);
      setWeightPounds(true);
    }
    setErrorList([
      {
        setterFn: setHardErrList,
        updates: {
          q5Empty: false,
        },
      },
      {
        setterFn: setSoftErrList,
        updates: {
          q5Unk_err: false,
        },
      },
    ]);
  };


  const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout } =
    useContext(AppContext);

  const [index_date, setIndexDate] = useState("[index_date]");

  const [chartAbstractionDate, setChartAbstractionDate] = useState("");
  const [diagnosisDate, setdiagnosisDate] = useState("");

  const pid = GetLocalStorageData("patientId") ?? 0;

  const InitialSoftErrList = {
    q1Unk_err: false,
    q2Unk_err: false,
    q3Unk_err: false,
    q4Unk_err: false,
    q5Unk_err: false,
    q6Unk_err: false,
    q7Unk_err: false,
    q8Unk_err: false,
    q9Unk_err: false,
  };

  const InitialHardErrList = {
    q1Empty: false,
    q2Empty: false,
    q2EmptyOtherTxt: false,
    q3Empty: false,
    q4Empty: false,
    q4Empty_units:false,
    q5Empty: false,
    q5Empty_units:false,
    q6Empty: false,
    q6EmptyOtherTxt: false,
    q7Empty: false,
    q8Empty: false,
    q8_InvalidDate1:false,
    q8_InvalidDate2:false,
    q9Empty: false,
    q9EmptyOtherTxt: false,
    q10Empty: false,
    q10_date_invalid1:false,
    q10_date_invalid2:false,
    q10_date_invalid3:false,
    
  };

  const [softErrList, setSoftErrList] = useState(InitialSoftErrList);
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  const HandleFieldChange = (e) => {
  
    const { name, id, value } = e.target;
    switch (name) {
      case "q1_pt_gender": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q1Empty: false,
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q1Unk_err: parseInt(value) === 99,
            },
          },
        ]);


        break;
      }

      case "q2_raceWhite":
      case "q2_raceBA":
      case "q2_raceAsian":
      case "q2_raceNA_AI":
      case "q2_raceNH_OPI":
      case "q2_raceOther": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          q2RaceUnk: false,
          ...(name === "q2_raceOther" && { q2_race_other_txt: "" }),
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q2Empty: false,
              q2EmptyOtherTxt: false,
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q2Unk_err: parseInt(value) === 99,
            },
          },
        ]);

        break;
      }

      case "q2_race_other_txt": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: value,
          q2RaceUnk: false,
          q2_raceOther: true,
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q2Empty: false,
              q2EmptyOtherTxt: false,
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q2Unk_err: parseInt(value) === 99,
            },
          },
        ]);


        break;
      }

      case "q2RaceUnk": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          q2_raceWhite: false,
          q2_raceBA: false,
          q2_raceAsian: false,
          q2_raceNA_AI: false,
          q2_raceNH_OPI: false,
          q2_raceOther: false,
          q2_race_other_txt: "",
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q2Empty: false,
              q2EmptyOtherTxt: false,
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q2Unk_err: parseInt(value) === 99,
            },
          },
        ]);
        break;
      }

      case "q3race_hispanic": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q3Empty: false,
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q3Unk_err: parseInt(value) === 99,
            },
          },
        ]);

        break;
      }

      case "q4Height": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q4_Height_txt: "",
        //  heightCentimeter: value == 99 ? setHeightCentimeters(0)
        }));

        if(Number(value) == 99){
          setHeightCentimeters(0);
          setHeightInches(0);
          setSelectedHeight(null);
        }
        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q4Empty: false,

            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q4Unk_err: parseInt(value) === 99,
            },
          },
        ]);

        break;
      }

      case "q4_Height_txt": {
        if (!AllowPositiveDecimalNumbers(value)) {
          break;
      }
        setPtDemographicsDetails((prev) => ({
          ...prev,
          q4Height: 1,
          [name]: value,
          //  q4Height: value !== 99 ? false : true
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q4Empty: false,

            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q4Unk_err: false,
            },
          },
        ]);

        break;
      }

      case "selectedHeight": {
        if (value !== "" && !AllowOnlyIntegers(value)) {
          break;
        }
        setPtDemographicsDetails((prev) => ({
          ...prev,
          q4Height: 1,
          [name]: value,
        }));
        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q4Empty: false,

            },
          }
          
        ]);

        break;
      }

      case "q5Weight": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q5_Weight_txt: "",
        }));

        if(Number(value) == 99){
          setWeightKilogram(0);
          setWeightPounds(0);
          setSelectedWeight(null);
        }
        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q5Empty: false,
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q5Unk_err: parseInt(value) === 99,
            },
          },
        ]);

        break;
      }
 
      case "q5_Weight_txt": {
        if (!AllowPositiveDecimalNumbers(value)) {
          break;
      }
        setPtDemographicsDetails((prev) => ({
          ...prev,
          q5Weight: 1,
          [name]: value,
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q5Empty: false,
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q5Unk_err: false,
            },
          },
        ]);

        break;
      }

      case "selectedWeight": {
        if (value !== "" && !AllowOnlyIntegers(value)) {
          break;
        }
        setPtDemographicsDetails((prev) => ({
          ...prev,
          q5Weight: 1,
          [name]: value,
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q5Empty: false,
            },
          }
        ]);

        break;
      }

      case "q6vaxPneumo":
      case "q6vaxInfluenza":
      case "q6vaxOther": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev[name],
          q6vaxNoneUnk: false,
          ...(name === "q6vaxOther" && { q6vaxOtherTxt: "" }),

        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q6Empty: false,
              q6EmptyOtherTxt:false
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q6Unk_err: parseInt(value) === 99,
            },
          },
        ]);
        break;
      }

      case "q6vaxOtherTxt": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: value,
          q6vaxNoneUnk: false,
          q6vaxOther: true,
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q6Empty: false,
              q6EmptyOtherTxt: value === "",
              q6EmptyOtherTxt:false,
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q6Unk_err: false,
            },
          },
        ]);
        break;
      }

      case "q6vaxNoneUnk": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          q6vaxPneumo: false,
          q6vaxInfluenza: false,
          q6vaxOther: false,
          q6vaxOtherTxt: "",
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q6Empty: false,
              q6EmptyOtherTxt:false
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q6Unk_err: parseInt(value) === 99,
            },
          },
        ]);


        break;
      }

      case "q8DeathDtUnk": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          yod: DefaultDate,
        }));


        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q8Empty: false,
              q8_InvalidDate1: false,
              q8_InvalidDate2: false,
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q8Unk_err: parseInt(value) === 99,
            },
          },
        ]);
        break;
      }

      case "q7PtAlive": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
          q9CauseOfDeath: null,
          q8DeathDtUnk: null,
          q9CauseOfDeath_Txt: "",
          yod:DefaultDate,
       
          
        }));
;

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q7Empty: false,
              q8Empty:false,
              q8_InvalidDate1: false,
              q8_InvalidDate2: false,
              q9Empty:false,
              q9EmptyOtherTxt:false,
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q7Unk_err: parseInt(value) === 99,
              q8Unk_err:false,
              q9Unk_err:false,
            },
          },
        ]);
        break;
      }

      // case "q8DeathDtUnk": {
      //   const value_int = Number(value);
      //   setPtDemographicsDetails((prev) => ({
      //     ...prev,
      //     [name]: Number(value),
      //     yod: DefaultDate, //reset q8 date
      //   }));

      //   setSoftErrList((prev) => ({
      //     ...prev,
      //     //  s5Terminate: value_int === 99,
      //     //  s5_unk:value_int === 99,
      //   }));

      //   setHardErrList((prev) => ({
      //     ...prev,
      //     q8Empty: false,
      //     q8_InvalidDate1: false,
      //     q8_InvalidDate2: false,
      //   }));
      //   break;
      // }

      case "q9CauseOfDeath": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: parseInt(value),
          q9CauseOfDeath_Txt: "",
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q9Empty: false,
              q9EmptyOtherTxt:false,
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q9Unk_err: parseInt(value) === 99,
            },
          },
        ]);
        break;
      }

      case "q9CauseOfDeath_Txt": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: value,
          q9CauseOfDeath: 3,
          // q2_race_other_txt: "",
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q9Empty: false,
              q9EmptyOtherTxt:false,
            },
          }
        ]);
        break;
      }

      //10
      case "q10_lcv_dx_date": {

        const value_int = Number(value);
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          q10_lcv_dx_date: DefaultDate, //reset S5 date
        }));


        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q10Empty: false,
            },
          }
        ]);
        break;
      }
      default:
        break;
    }
  };

  const ValidateForm = () => {

    let isValid = true;
    // Validation for q1
    if (ptDemographicsDetails.q1_pt_gender === null) {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q1Empty: true,
          },
        }
      ]);
      isValid = false;
    } else {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q1Empty: false,
          },
        }
      ]);
    }

    // Validation for q2
    const {
      q2_raceWhite,
      q2_raceBA,
      q2_raceAsian,
      q2_raceNA_AI,
      q2_raceNH_OPI,
      q2_raceOther,
      q2_race_other_txt,
      q2RaceUnk,
    } = ptDemographicsDetails;

    if (
      !q2_raceWhite &&
      !q2_raceBA &&
      !q2_raceAsian &&
      !q2_raceNA_AI &&
      !q2_raceNH_OPI &&
      !q2_raceOther &&
      q2RaceUnk !== 99
     
    ) {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q2Empty: true,
          },
        }
      ]);
      isValid = false;
    } else {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q2Empty: false,
          },
        }
      ]);
    }

    if (q2_raceOther && q2_race_other_txt.trim() === "") {

      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q2EmptyOtherTxt: true,
          },
        }
      ]);
      isValid = false;
    } else {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q2EmptyOtherTxt: false,
          },
        }
      ]);
    }

    // if (q2RaceUnk === 99) {
    //   setSoftErrList((prev) => ({
    //     ...prev,
    //     q2Unk_err: true,
    //   }));
    // } else {
    //   setSoftErrList((prev) => ({
    //     ...prev,
    //     q2Unk_err: false,
    //   }));
    // }

    // validation for Q3

    if (ptDemographicsDetails.q3race_hispanic === null) {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q3Empty: true,
          },
        }
      ]);
      isValid = false;
    } else {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q3Empty: false,
          },
        }
      ]);
    }



    // validation for Q4
if (ptDemographicsDetails.q4Height === null) {
  setErrorList([
    {
      setterFn: setHardErrList,
      updates: {
        q4Empty: true,
      },
    }
  ]);
  isValid = false;
}

 if (ptDemographicsDetails.q4Height === 1 && (!heightInch && !heightCentimeter || isStringEmpty(ptDemographicsDetails.q4_Height_txt) )) {
  setErrorList([
    {
      setterFn: setHardErrList,
      updates: {
        q4Empty: true,
        q4Empty_units: true,
      },
    }
  ]);
  isValid = false;
}


// validation for Q5
if (ptDemographicsDetails.q5Weight === null) {
  setErrorList([
    {
      setterFn: setHardErrList,
      updates: {
        q5Empty: true,
      },
    }
  ]);
  isValid = false;
} else if (ptDemographicsDetails.q5Weight === 1 && (!weightKilograms && !weightPounds || isStringEmpty(ptDemographicsDetails.q5_Weight_txt) )) {

  setErrorList([
    {
      setterFn: setHardErrList,
      updates: {
        q5Empty: true,
    q5Empty_units: true,
      },
    }
  ]);
  isValid = false;
} 



    // Validation for Q6
    const {
      q6vaxPneumo,
      q6vaxInfluenza,
      q6vaxOther,
      q6vaxOtherTxt,
      q6vaxNoneUnk,
    } = ptDemographicsDetails;

    if (!q6vaxPneumo && !q6vaxInfluenza && !q6vaxOther && q6vaxNoneUnk !== 99 && q6vaxNoneUnk !==1) {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q6Empty: true,
          },
        }
      ]);
      isValid = false;
    } else {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q6Empty: false,
          },
        }
      ]);
    }

    if (q6vaxOther && q6vaxOtherTxt.trim() === "") {

      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q6EmptyOtherTxt: true,
          },
        }
      ]);
      isValid = false;
    } else {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q6EmptyOtherTxt: false,
          },
        }
      ]);
    }


    // Validation for q7

    if (ptDemographicsDetails.q7PtAlive === null) {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q7Empty: true,
          },
        }
      ]);
      isValid = false;
    } else if (
      ptDemographicsDetails.q7PtAlive === 1 ||
      ptDemographicsDetails.q7PtAlive === 99
    ) {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q8Empty: false,
            q9Empty: false,
          },
        }
      ]);
    } else {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q7Empty: false,
          },
        }
      ]);
    }

    //q8

    if(ptDemographicsDetails.q7PtAlive === 0 ) {
      if(ptDemographicsDetails.q8DeathDtUnk === null || (ptDemographicsDetails.q8DeathDtUnk === 1 && IsDateEmpty(ptDemographicsDetails.yod))){

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q8Empty: true,

            },
          }
        ]);
        isValid = false;
      }

    } 

    if(ptDemographicsDetails.q8DeathDtUnk === 1 && ptDemographicsDetails.yod){
       if(!IsDateEmpty(ptDemographicsDetails.yod ) && IsDateValid(ptDemographicsDetails.yod) && !IsDateEmpty(index_date ) && !q8ValidDate1(ptDemographicsDetails.yod,index_date)){

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q8Empty: false,
          q8_InvalidDate1: true
            },
          }
        ]);
        isValid = false;
      }
    }

if(ptDemographicsDetails.q8DeathDtUnk === 1 && ptDemographicsDetails.yod){
       if(!IsDateEmpty(ptDemographicsDetails.yod ) && IsDateValid(ptDemographicsDetails.yod) &&  !IsDateEmpty(chartAbstractionDate ) && !q8ValidDate2(ptDemographicsDetails.yod,chartAbstractionDate)){

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q8Empty: false,
          q8_InvalidDate2: true
            },
          }
        ]);
        isValid = false;
      }
    }


    // Validation for Q9
    const { q9CauseOfDeath, q9CauseOfDeath_Txt } = ptDemographicsDetails;

    if (ptDemographicsDetails.q7PtAlive == 0 && q9CauseOfDeath === null) {


      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q9Empty: true,
          },
        }
      ]);


      isValid = false;
    } else if (q9CauseOfDeath === 3 && q9CauseOfDeath_Txt.trim() === "") {
      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q9EmptyOtherTxt: true,
          },
        }
      ]);
      isValid = false;
    } else {

      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q9Empty: false,
        q9EmptyOtherTxt: false,
          },
        }
      ]);
    }

    //Q10
    if (ptDemographicsDetails.q10_lcv_dx_date) {
      if (
        ptDemographicsDetails.q10_lcv_dx_date &&
        IsDateEmpty(ptDemographicsDetails.q10_lcv_dx_date)
      ) {
        isValid = false;

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q10Empty: true,
            },
          }
        ]);
      }

      // else if(!IsDateValid(ptDemographicsDetails.q10_lcv_dx_date )){
      //   isValid = false;
      // }
// 
     else  if (!IsDateEmpty(ptDemographicsDetails.q10_lcv_dx_date )&& IsDateValid(ptDemographicsDetails.q10_lcv_dx_date) && !IsDateEmpty(index_date) && index_date !=="[index_date]" && !IsValidDate1(ptDemographicsDetails.q10_lcv_dx_date,index_date)) {

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q10Empty: false,
              q10_date_invalid1: true
            },
          }
        ]);
        isValid = false;
      }

      
      else  if (!IsDateEmpty(ptDemographicsDetails.q10_lcv_dx_date ) && IsDateValid(ptDemographicsDetails.q10_lcv_dx_date) && !IsDateEmpty(diagnosisDate ) &&   !IsValidDate2(ptDemographicsDetails.q10_lcv_dx_date,diagnosisDate)) {

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q10Empty: false,
          q10_date_invalid2: true
            },
          }
        ]);
        isValid = false;
      }


    else  if (!IsDateEmpty(ptDemographicsDetails.q10_lcv_dx_date ) && IsDateValid(ptDemographicsDetails.q10_lcv_dx_date) &&  !IsDateEmpty(chartAbstractionDate ) &&  !IsValidDate3(ptDemographicsDetails.q10_lcv_dx_date,chartAbstractionDate)) {

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q10Empty: false,
              q10_date_invalid3: true
            },
          }
        ]);
        isValid = false;
      }


    }  
 else if (!IsDateEmpty(ptDemographicsDetails.q10_lcv_dx_date ) && ptDemographicsDetails.q10_lcv_dx_date === DefaultDate) {

      setErrorList([
        {
          setterFn: setHardErrList,
          updates: {
            q10Empty: true,

          },
        }
      ]);
      isValid = false;
    } 
    // else {
    //   setHardErrList((prev) => ({
    //     ...prev,
    //     q10Empty: false,
    //   }));
    //   // isValid = true;
    // }

    return isValid;
  };

  const [ptDemographicsDetails, setPtDemographicsDetails] = useState({
    userId: GetLoggedInUserID(),
    q1_pt_gender: null,
    q2_raceWhite: false,
    q2_raceBA: false,
    q2_raceAsian: false,
    q2_raceNA_AI: false,
    q2_raceNH_OPI: false,
    q2_raceOther: false,
    q2_race_other_txt: "",
    q2RaceUnk: null,

    q3race_hispanic: null,
    q4Height: false,
    q4_Height_txt: "",

    q5Weight: false,
    q5_Weight_txt: "",

    q6vaxPneumo: false,
    q6vaxInfluenza: false,
    q6vaxOther: false,
    q6vaxOtherTxt: "",
    q6vaxNoneUnk: null,
    q7PtAlive: null,
    q8DeathDtUnk: null,
    q11DeathDtUnk: null,
    yod: "",
    q9CauseOfDeath: null,
    q9CauseOfDeath_Txt: "",
    q10lcv: "",
    q10_lcv_dx_date: DefaultDate,
  });
  const navigatePaths = {
    prevPage: "/PatientScreening",
    currPage: "/Demographics",
    nextPage: "/ClinicalCharacteristics",
  };

  const SavePage = async (validate) => {
    try {

      ToggleLoader(true);
      setSoftErrList(InitialSoftErrList);
      setHardErrList(InitialHardErrList);



      let isValid = validate ? ValidateForm() : true;


      if(!IsDateEmpty(ptDemographicsDetails.q10_lcv_dx_date) && !IsDateValid(ptDemographicsDetails.q10_lcv_dx_date))
      {
         isValid = false;
      }

      if(!IsDateEmpty(ptDemographicsDetails.yod) && !IsDateValid(ptDemographicsDetails.yod))
      {
        isValid = false;
      }

      // if (!ValidateForm()) {
      //   setShowTopErrMsg(true);
      //   ToggleLoader(false);
      //   return false;
      // }
      // setShowTopErrMsg(false);
      if (isValid) {
        setShowTopErrMsg(false)
      const demographicModel = {
        patientId: pid,
        EncryptedPatientId: uniqueEncryptedPatientId,
        gender: ptDemographicsDetails.q1_pt_gender,
        raceWhite: ptDemographicsDetails.q2_raceWhite,
        raceBlack: ptDemographicsDetails.q2_raceBA,
        raceAsian: ptDemographicsDetails.q2_raceAsian,
        raceNativeAmerican: ptDemographicsDetails.q2_raceNA_AI,
        raceHawaiian: ptDemographicsDetails.q2_raceNH_OPI,
        raceOther: ptDemographicsDetails.q2_raceOther,
        raceothertxt: ptDemographicsDetails.q2_race_other_txt?ptDemographicsDetails.q2_race_other_txt: "",
        raceUnk: ptDemographicsDetails.q2RaceUnk,
        ethnicity: ptDemographicsDetails.q3race_hispanic,

        heightCentimeters: heightCentimeter, // ptDemographicsDetails.heightCentimeter,
        heightInches: heightInch, //ptDemographicsDetails.heightInches,
        heightTxt: ptDemographicsDetails.q4_Height_txt? ptDemographicsDetails.q4_Height_txt:"",
        heightUnk: ptDemographicsDetails.q4Height === 99? true : false,

        weightTxt: ptDemographicsDetails.q5_Weight_txt?ptDemographicsDetails.q5_Weight_txt:"",
        weightKilos: weightKilograms, //ptDemographicsDetails.q5Weight,
        weightPounds: weightPounds, //ptDemographicsDetails.q5Weight,
        weightUnk: ptDemographicsDetails.q5Weight===99 ? true  : false,

        vaxPneumo: ptDemographicsDetails.q6vaxPneumo,
        vaxInfluenza: ptDemographicsDetails.q6vaxInfluenza,
        vaxOther: ptDemographicsDetails.q6vaxOther,
        vaxOtherTxt: ptDemographicsDetails.q6vaxOtherTxt,
        vaccineNoneofabove:
          ptDemographicsDetails.q6vaxNoneUnk === 1 ? true : false,
        VaccineUnk: ptDemographicsDetails.q6vaxNoneUnk === 99 ? true : false,
        stillAlive: ptDemographicsDetails.q7PtAlive,

        // deathDateUnk: ptDemographicsDetails.q11DeathDtUnk,

        deathCause: ptDemographicsDetails.q9CauseOfDeath,
        deathCauseOthTxt: ptDemographicsDetails.q9CauseOfDeath_Txt,
        deathCauseOth:
          ptDemographicsDetails.q9CauseOfDeath === 3 ? true : false,
        deathCauseUnk:
          ptDemographicsDetails.q9CauseOfDeath === 99 ? true : false,

        deathDate:ptDemographicsDetails.q8DeathDtUnk === 1 && !IsDateEmpty(ptDemographicsDetails.yod)? ParseDate(ptDemographicsDetails.yod): null,
        deathDateDmuid:ptDemographicsDetails.q8DeathDtUnk === 1? GetDMUID(ptDemographicsDetails.yod): null,
        deathDateUnk:ptDemographicsDetails.q8DeathDtUnk === 99? ptDemographicsDetails.q8DeathDtUnk: false,

        LastclinicalvisitDate: !IsDateEmpty(ptDemographicsDetails.q10_lcv_dx_date)? ParseDate(ptDemographicsDetails.q10_lcv_dx_date):null,
        LastclinicalvisitDateDmuid: ptDemographicsDetails.q10_lcv_dx_date
          ? GetDMUID(ptDemographicsDetails.q10_lcv_dx_date)
          : null,

        createdby: GetLoggedInUserID() ?? 0,
        editedby: GetLoggedInUserID() ?? 0,
        userId: GetLoggedInUserID(),
        isValidated: validate,
      };
      if (pid > 0) {
        const response = await PatientDemographicsService.SaveDemographic(
          demographicModel
        );
        ToggleLoader(false);
        if (response.status !== HTTPResponse.OK) {
          if (response?.status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          } else throw response.error;
        } else{
          await LoadData();
           ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
        }
      }
      return true;
    }
    else{
      ToggleLoader(false);
      setShowTopErrMsg(true)
      return false;
    }
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  useEffect(() => {
    async function FetchData() {
      if (!isNaN(pid) && Number(pid) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);

  const LoadData = async () => {

    try {
      if (pid > 0) {
        const response = await PatientDemographicsService.GetAllDemographics(
          pid
        );
        if (response?.status === HTTPResponse.OK) {
          const { data } = response;
          setPtDemographicsDetails((prev) => ({
            ...prev,
            userId: data.userId,
            q1_pt_gender: data.gender != null ? data.gender : null,
            q2_raceWhite: data.raceWhite,
            q2_raceBA: data.raceBlack,
            q2_raceAsian: data.raceAsian,
            q2_raceNA_AI: data.raceNativeAmerican,
            q2_raceNH_OPI: data.raceHawaiian,
            q2_raceOther: data.raceOther,
            q2_race_other_txt: data.raceOtherTxt,
            q2RaceUnk: data.raceUnk === true ? 99 : false,

            q3race_hispanic: data.ethnicity != null ? data.ethnicity : null,
            q4Height:  !isStringEmpty(data.heightTxt)? 1 : data.heightUnk? 99:null,
            q4_Height_txt:  !isStringEmpty(data.heightTxt) ? data.heightTxt : null,

            heightCentimeter: data.heightCentimeters === true? 1: null ,
            heightInch: data.heightInches,

            q5_Weight_txt:  !isStringEmpty(data.weightTxt) ? data.weightTxt : null,

            q5Weight: !isStringEmpty(data.weightTxt) ? 1 :  data.weightUnk? 99:null,
            weightKilograms: data.weightKilograms,
            weightPounds: data.weightPounds,

            q6vaxPneumo: data.vaxPneumo != null ? data.vaxPneumo : "",
            q6vaxInfluenza: data.vaxInfluenza != null ? data.vaxInfluenza : "",
            q6vaxOther: data.vaxOther != null ? data.vaxOther : "",
            q6vaxOtherTxt: data.vaxOtherTxt != null ? data.vaxOtherTxt : "",
            q6vaxNoneUnk: data.vaccineUnk == true ? 99 : false,

            q7PtAlive: data.stillAlive != null ? data.stillAlive : null,

            q8DeathDtUnk: data.deathDtUnk != null ? data.deathDtUnk : null,

            q9CauseOfDeath: data.deathCause != null ? data.deathCause : null,
            q9CauseOfDeath_Txt:
              data.deathCauseOthTxt != null ? data.deathCauseOthTxt : "",

            q10_lcv_dx_date: !isStringEmpty(data.lastClinicalVisitDate)
              ? GetDisplayDate(data.lastClinicalVisitDate,data.lastClinicalVisitDateDmuid)
              : DefaultDate,

            yod: !isStringEmpty(data.deathDate)
              ? GetDisplayDate(data.deathDate,data.deathDateDmuid)
              : DefaultDate,

            q8DeathDtUnk: data.deathDateUnk? 99: data.deathDate != null? 1: null,


          }));
            // Set selected height and weight based on data
            if (data.heightCentimeters == true) {
              setSelectedHeight("centimeters");
              setHeightCentimeters(true);
              setHeightInches(false);
            } else if (data.heightInches===true) {
              setSelectedHeight("inches");
              setHeightCentimeters(false);
              setHeightInches(true);
            }
            else{
              setHeightCentimeters(0);
              setHeightInches(0);
            }
  
            if (data.weightKilos===true) {
              setSelectedWeight("kilograms");
              setWeightKilogram(true);
              setWeightPounds(false);
            } else if (data.weightPounds ===true) {
              setSelectedWeight("pounds");
              setWeightKilogram(false);
              setWeightPounds(true);
            }
            else{
              setWeightKilogram(0);
              setWeightPounds(0);
            }


            if(data.indexDate){
              console.log("Index Date:", data.indexDate);
              setIndexDate(data.indexDate);
            }

            if(data.chartAbstractDate){
              setChartAbstractionDate(data.chartAbstractDate);
            }
            if(data.lbclDxDate ){
              setdiagnosisDate(data.lbclDxDate);
            }
            
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  };



  const HandleDateChange = (name, date, datePart) => {

    switch (name) {
      case "yod": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          q8DeathDtUnk: 1,
          yod: SetDateState(prev.yod, date, datePart),
        }));



        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q8Empty: false,
              q8_InvalidDate1:false,
              q8_InvalidDate2:false,
            },
          },
          {
            setterFn: setSoftErrList,
            updates: {
              q8Unk_err:false,

            },
          },
        ]);
        break;
      }

      case "q10_lcv_dx_date": {
    
        setPtDemographicsDetails((prev) => ({
          ...prev,
          q10_lcv_dx_date: SetDateState(prev.q10_lcv_dx_date, date, datePart),
        }));

        setErrorList([
          {
            setterFn: setHardErrList,
            updates: {
              q10Empty: false,
              q10_date_invalid1:false,
              q10_date_invalid2:false,
              q10_date_invalid3:false,
            },
          }
        ]);

        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>

      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.Demographics} />
          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>DEMOGRAPHIC CHARACTERISTICS</h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div style={PageDesignConstants.PageDescriptionStyle}>
                    <i>
                      In this section, you will be asked to answer questions
                      related to a patient’s demographic characteristics.
                    </i>
                  </div>
                  <div className="question-answer">
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q1.</span>
                        <span className="quest-text-pad">
                          What is the patient’s sex?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <input
                            type="radio"
                            id="q1Male"
                            name="q1_pt_gender"
                            value={1}
                            checked={ptDemographicsDetails.q1_pt_gender === 1}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q1Male">
                            <span className="radio-text-padding">Male</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <input
                            type="radio"
                            id="q1Female"
                            name="q1_pt_gender"
                            value={2}
                            checked={ptDemographicsDetails.q1_pt_gender === 2}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q1Female">
                            <span className="radio-text-padding">Female</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <input
                            type="radio"
                            id="q1Other"
                            name="q1_pt_gender"
                            value={3}
                            checked={ptDemographicsDetails.q1_pt_gender === 3}
                            onChange={HandleFieldChange}
                          />

                          <label htmlFor="q1Other">
                            <span className="radio-text-padding">Other</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <input
                            type="radio"
                            id="q1Unk"
                            name="q1_pt_gender"
                            value={99}
                            checked={ptDemographicsDetails.q1_pt_gender === 99}
                            onChange={HandleFieldChange}
                          />

                          <label htmlFor="q1Unk">
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.q1Empty}
                        message={Demographic_Msgs.Q1}
                      />
                      <ErrorField
                        show={softErrList.q1Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                    </div>

                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q2.</span>
                        <span className="quest-text-pad">
                          Which of the following best describes the patient’s
                          race? <i>(select all that apply)</i>
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input
                            type="checkbox"
                            id="q2_raceWhite"
                            name="q2_raceWhite"
                            checked={ptDemographicsDetails.q2_raceWhite}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q2_raceWhite">
                            <span className="radio-text-padding">White</span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q2_raceBA"
                            name="q2_raceBA"
                            checked={ptDemographicsDetails.q2_raceBA}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q2_raceBA">
                            <span className="radio-text-padding">
                              Black or African American
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q2_raceAsian"
                            name="q2_raceAsian"
                            checked={ptDemographicsDetails.q2_raceAsian}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q2_raceAsian">
                            <span className="radio-text-padding">Asian</span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q2_raceNA_AI"
                            name="q2_raceNA_AI"
                            checked={ptDemographicsDetails.q2_raceNA_AI}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q2_raceNA_AI">
                            <span className="radio-text-padding">
                              Native American or American Indian
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q2_raceNH_OPI"
                            name="q2_raceNH_OPI"
                            checked={ptDemographicsDetails.q2_raceNH_OPI}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q2_raceNH_OPI">
                            <span className="radio-text-padding">
                              Native Hawaiian or Other Pacific Islander
                            </span>
                          </label>
                        </div>
                        <div className="">
                          <input
                            type="checkbox"
                            id="q2_raceOther"
                            name="q2_raceOther"
                            checked={ptDemographicsDetails.q2_raceOther}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q2_raceOther">
                            <span className="radio-text-padding">
                              Other, specify:{" "}
                              <input
                                className="input-dash"
                                name="q2_race_other_txt"
                                type="text"
                                value={ptDemographicsDetails.q2_race_other_txt}
                                maxLength={100}
                                onChange={HandleFieldChange}
                              />
                            </span>
                          </label>
                        </div>
                        <div className="">
                        <label >
                          <input
                            type="radio"
                            id="q2RaceUnknown"
                            name="q2RaceUnk"
                            value={99}
                            checked={ptDemographicsDetails.q2RaceUnk === 99}
                            onChange={HandleFieldChange}
                          />
                         
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.q2Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q2Empty}
                        message={Demographic_Msgs.Q2}
                      />
                      <ErrorField
                        show={hardErrList.q2EmptyOtherTxt}
                        message="Please enter the text."
                      />
                    </div>

                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q3.</span>
                        <span className="quest-text-pad">
                          Which of the following best describes the patient’s
                          ethnicity?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                        <label>
                          <input
                            type="radio"
                            id="q3race_hispanic"
                            name="q3race_hispanic"
                            value={1}
                            checked={
                              ptDemographicsDetails.q3race_hispanic === 1
                            }
                            onChange={HandleFieldChange}
                          />
                       
                            <span className="radio-text-padding">Hispanic</span>
                          </label>
                        </div>
                        <div className="">
                        <label>
                          <input
                            type="radio"
                            id="q3race_nonhispanic"
                            name="q3race_hispanic"
                            value={2}
                            checked={
                              ptDemographicsDetails.q3race_hispanic === 2
                            }
                            onChange={HandleFieldChange}
                          />
                          
                            <span className="radio-text-padding">
                              Non-Hispanic
                            </span>
                          </label>
                        </div>
                        <div className="">
                        <label>
                          <input
                            type="radio"
                            id="q3race_hispanicUK"
                            name="q3race_hispanic"
                            value={99}
                            checked={
                              ptDemographicsDetails.q3race_hispanic === 99
                            }
                            onChange={HandleFieldChange}
                          />
                        
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      {/* <ErrorField show={hardErrList.q19Empty} message={demographicScreen_Msgs.Q19} /> */}
                      <ErrorField
                        show={softErrList.q3Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q3Empty}
                        message={Demographic_Msgs.Q3}
                      />
                    </div>

                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q4.</span>
                        <span className="quest-text-pad">
                          What is the patient’s height at, or closest to {""}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                            {index_date}
                          </span>
                          , the date of initiation of treatment with
                          epcoritamab?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                        <label>
                          <input
                            type="radio"
                            id="q4DimensionSelect_1"
                            name="q4Height"
                            value={1}
                            checked={ptDemographicsDetails.q4Height === 1}
                            onChange={HandleFieldChange}
                          />
                          {/* &nbsp; &nbsp; */}
                          
                            <span className="radio-text-padding">Height:</span>
                          </label>{" "}
                          {""}
                          <label>
                            <input
                              className="sub-input-dash"
                              name="q4_Height_txt"
                              type="text"
                              value={ptDemographicsDetails.q4_Height_txt}
                              maxLength={8}
                              onChange={HandleFieldChange}
                              onKeyDown={(e) => {
                                // Allow backspace, arrow keys, and delete
                                if (
                                  [
                                    "Backspace",
                                    "ArrowLeft",
                                    "ArrowRight",
                                    "Delete",
                                    "Tab",
                                  ].includes(e.key)
                                ) {
                                  return;
                                }

                                // Prevent non-numeric characters, except '.'
                                if (
                                  !/[0-9.]/.test(
                                    e.key
                                  )
                                ) {
                                  e.preventDefault();
                                  return;
                                }

                                const value =
                                  e.target.value;
                                const selectionStart =
                                  e.target
                                    .selectionStart;
                                const newValue =
                                  value.slice(
                                    0,
                                    selectionStart
                                  ) +
                                  e.key +
                                  value.slice(
                                    e.target
                                      .selectionEnd
                                  );

                                // Prevent multiple '.'
                                if (
                                  e.key === "." &&
                                  value.includes(".")
                                ) {
                                  e.preventDefault();
                                  return;
                                }

                                // Prevent '.' as the first character
                                if (
                                  e.key === "." &&
                                  value === ""
                                ) {
                                  e.preventDefault();
                                  return;
                                }

                                // Allow only one digit after decimal point
                                if (
                                  newValue.includes(
                                    "."
                                  ) &&
                                  newValue.split(
                                    "."
                                  )[1].length > 2
                                ) {
                                  e.preventDefault();
                                  return;
                                }
                              }}
                            />

                            <span className="radio-text-padding leftPad8">
                              <Dropdown className="custom-dropdown-caret">
                                <Dropdown.Toggle
                                  variant=""
                                  id="dropdown-basic"
                                  className="w110 epcoritamab-custom-dropdown-button"
                                >
                                  {selectedHeight !== null
                                    ? selectedHeight
                                    : "Select"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="w110 Demographic-custom-dropdown-menu">
                                  {[
                                    { id: 1, text: "centimeters" },
                                    { id: 2, text: "inches" },
                                  ].map((unit) => (
                                    <Dropdown.Item
                                      as="button"
                                      name="q4_Height_rd"
                                      key={unit.id}
                                      value={unit.text}
                                      className={`custom-dropdown-item ${
                                        unit.text === selectedHeight &&
                                        "custom-dropdown-item-selected"
                                      }`}
                                      style={{ width: "100% !important" }}
                                      onClick={() =>
                                        setSelectedHeight(unit.text) &
                                        handleHeightChange(unit.text)
                                      } 
                                    >
                                      {unit.text}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </span>
                          </label>
                          &nbsp;&nbsp;&nbsp;
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            id="q4Height_99"
                            name="q4Height"
                            value={99}
                            checked={ptDemographicsDetails.q4Height === 99}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q4Height_99">
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      {/* <ErrorField show={hardErrList.q19Empty} message={demographicScreen_Msgs.Q19} /> */}
                      <ErrorField
                        show={softErrList.q4Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q4Empty}
                        message={Demographic_Msgs.Q4}
                      />

                        {/* <ErrorField
                        show={hardErrList.q4Empty_units}
                        message={Demographic_Msgs.Q4_units}
                      /> */}
                    </div>

                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q5.</span>
                        <span className="quest-text-pad">
                          What is the patient’s weight at, or closest to{" "}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                            {index_date}
                          </span>
                          , the date of initiation of treatment with
                          epcoritamab?
                        </span>
                      </div>

                      <div className=" double-dig-answer">
                        <div className="">
                        <label>
                          <input
                            type="radio"
                            id="q5DimensionSelect_1"
                            name="q5Weight"
                            value={1}
                            checked={ptDemographicsDetails.q5Weight === 1}
                            onChange={HandleFieldChange}
                          />
                          {/* &nbsp; &nbsp; */}
                          
                            <span className="radio-text-padding">Weight:</span>
                          </label>{" "}
                          {""}
                          {/* <label >
                            <input
                              className="sub-input-dash"
                              name="q5_weight_txt"
                              type="text"
                              value={ptDemographicsDetails.q5_Weight_txt}
                              maxLength={100}
                              onChange={HandleFieldChange}
                            /> */}
                          <label>
                            <input
                              className="sub-input-dash"
                              name="q5_Weight_txt"
                              type="text"
                              value={ptDemographicsDetails.q5_Weight_txt}
                              maxLength={8}
                              onChange={HandleFieldChange}
                              onKeyDown={(e) => {
                                // Allow backspace, arrow keys, and delete
                                if (
                                  [
                                    "Backspace",
                                    "ArrowLeft",
                                    "ArrowRight",
                                    "Delete",
                                    "Tab",
                                  ].includes(e.key)
                                ) {
                                  return;
                                }

                                // Prevent non-numeric characters, except '.'
                                if (
                                  !/[0-9.]/.test(
                                    e.key
                                  )
                                ) {
                                  e.preventDefault();
                                  return;
                                }

                                const value =
                                  e.target.value;
                                const selectionStart =
                                  e.target
                                    .selectionStart;
                                const newValue =
                                  value.slice(
                                    0,
                                    selectionStart
                                  ) +
                                  e.key +
                                  value.slice(
                                    e.target
                                      .selectionEnd
                                  );

                                // Prevent multiple '.'
                                if (
                                  e.key === "." &&
                                  value.includes(".")
                                ) {
                                  e.preventDefault();
                                  return;
                                }

                                // Prevent '.' as the first character
                                if (
                                  e.key === "." &&
                                  value === ""
                                ) {
                                  e.preventDefault();
                                  return;
                                }

                                // Allow only one digit after decimal point
                                if (
                                  newValue.includes(
                                    "."
                                  ) &&
                                  newValue.split(
                                    "."
                                  )[1].length > 2
                                ) {
                                  e.preventDefault();
                                  return;
                                }
                              }}
                            />
                            <span className="radio-text-padding leftPad8">
                              <Dropdown className="custom-dropdown-caret">
                                <Dropdown.Toggle
                                  variant=""
                                  id="dropdown-basic"
                                  className="w110 epcoritamab-custom-dropdown-button"
                                >
                                  {selectedWeight !== null
                                    ? selectedWeight
                                    : "Select"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="w110 Demographic-custom-dropdown-menu">
                                  {[
                                    { id: 1, text: "kilograms" },
                                    { id: 2, text: "pounds" },
                                  ].map((unit) => (
                                    <Dropdown.Item
                                      as="button"
                                      name="q5Weight_rd"
                                      key={unit.id}
                                      value={unit.text}
                                      className={`custom-dropdown-item ${
                                        unit.text === selectedWeight &&
                                        "custom-dropdown-item-selected"
                                      }`}
                                      style={{ width: "100% !important" }}
                                      onClick={() =>
                                        setSelectedWeight(unit.text) &
                                        handleWeightChange(unit.text)
                                      } // & handleHeightChange(unit.text)
                                    >
                                      {unit.text}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </span>
                          </label>
                        </div>

                        <div className="">
                          <input
                            type="radio"
                            id="q5Weight_99"
                            name="q5Weight"
                            value={99}
                            checked={ptDemographicsDetails.q5Weight === 99}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q5Weight_99">
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      {/* <ErrorField show={hardErrList.q18Empty} message={demographicScreen_Msgs.Q18} /> */}
                      <ErrorField
                        show={softErrList.q5Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q5Empty}
                        message={Demographic_Msgs.Q5}
                      />

                        {/* <ErrorField
                        show={hardErrList.q5Empty_units}
                        message={Demographic_Msgs.Q5_units}
                      /> */}

                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q6.</span>
                        <span className="quest-text-pad">
                          Which of the following vaccines did the patient
                          receive during the 12 months prior to, and including{" "}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                            {index_date}
                          </span>
                          , the date of initiation of treatment with epcoritamab
                          (index date)?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                        <label >
                          <input
                            type="checkbox"
                            id="q6vaxPneumo"
                            name="q6vaxPneumo"
                            checked={ptDemographicsDetails.q6vaxPneumo}
                            onChange={HandleFieldChange}
                          />
                        
                            <span className="radio-text-padding">
                              Pneumococcal vaccine (Pneumovax 23/Prevnar 13)
                            </span>
                          </label>
                        </div>
                        <div className="">
                        <label >
                          <input
                            type="checkbox"
                            id="q6vaxInfluenza"
                            name="q6vaxInfluenza"
                            checked={ptDemographicsDetails.q6vaxInfluenza}
                            onChange={HandleFieldChange}
                          />
                          
                            <span className="radio-text-padding">
                              Influenza vaccine
                            </span>
                          </label>
                        </div>

                        <div className="">
                          <input
                            type="checkbox"
                            id="q6vaxOther"
                            name="q6vaxOther"
                            checked={ptDemographicsDetails.q6vaxOther}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q6vaxOther">
                            <span className="radio-text-padding">
                              Other, specify:{" "}
                              <input
                                className="input-dash"
                                name="q6vaxOtherTxt"
                                type="text"
                                value={ptDemographicsDetails.q6vaxOtherTxt}
                                maxLength={100}
                                onChange={HandleFieldChange}
                              />
                            </span>
                          </label>
                        </div>
                        <div className="">
                        <label >
                          <input
                            type="radio"
                            id="q6vaxNone"
                            name="q6vaxNoneUnk"
                            value={1}
                            checked={ptDemographicsDetails.q6vaxNoneUnk === 1}
                            onChange={HandleFieldChange}
                          />
                         
                            <span className="radio-text-padding">
                              None of the above
                            </span>
                          </label>
                        </div>
                        <div className="">
                        <label >
                          <input
                            type="radio"
                            id="q6vaxUnk"
                            name="q6vaxNoneUnk"
                            value={99}
                            checked={ptDemographicsDetails.q6vaxNoneUnk === 99}
                            onChange={HandleFieldChange}
                          />
                        
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.q6Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q6Empty}
                        message={Demographic_Msgs.Q6}
                      />
                      <ErrorField
                        show={hardErrList.q6EmptyOtherTxt}
                        message="Please enter the text."
                      />
                    </div>

                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q7.</span>
                        <span className="quest-text-pad">
                          Was the patient still alive as of {""}
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                            {chartAbstractionDate}
                          </span>
                          ?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                        <label htmlFor="q7PtAliveSelect_1">
                          <input
                            type="radio"
                            id="q7PtAliveSelect_1"
                            name="q7PtAlive"
                            value={1}
                            checked={ptDemographicsDetails.q7PtAlive === 1}
                            onChange={HandleFieldChange}
                          />
                          
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="">
                        <label htmlFor="q7PtAliveSelect_0">
                          <input
                            type="radio"
                            id="q7PtAliveSelect_0"
                            name="q7PtAlive"
                            value={0}
                            checked={ptDemographicsDetails.q7PtAlive === 0}
                            onChange={HandleFieldChange}
                          />
                          
                            <span className="radio-text-padding">No</span>
                          </label>
                        </div>
                        <div className="">
                        <label>
                          <input
                            type="radio"
                            id="q7PtAliveSelect_99"
                            name="q7PtAlive"
                            value={99}
                            checked={ptDemographicsDetails.q7PtAlive === 99}
                            onChange={HandleFieldChange}
                          />
                          
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={softErrList.q7Unk_err}
                        message={CommonMsgs.Unknown}
                      />
                      <ErrorField
                        show={hardErrList.q7Empty}
                        message={Demographic_Msgs.Q7}
                      />
                    </div>
                    {ptDemographicsDetails.q7PtAlive === 0 && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q8.</span>
                          <span className="quest-text-pad">
                            What was the patient’s date of death?
                          </span>
                        </div>
                        <div className="double-dig-answer">
                          <div className="date-field-padding">
                            <input
                              type="radio"
                              id="q8DeathDtUnk-select1"
                              name="q8DeathDtUnk"
                              value={1}
                              checked={ptDemographicsDetails.q8DeathDtUnk === 1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              <DateControl
                                ctrlId={"yod"}
                                HandleDateChange={HandleDateChange}
                                date={ptDemographicsDetails.yod}
                                showUnkInfoTxt={true}
                              />
                              {/* <i>
                                If the exact month and/or day is unknown, please
                                enter UNK.
                              </i> */}
                            </span>
                          </div>
                          <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="q8DeathDtUnk-select99"
                              name="q8DeathDtUnk"
                              value={99}
                              checked={
                                ptDemographicsDetails.q8DeathDtUnk === 99
                              }
                              onChange={HandleFieldChange}
                            />
                            
                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>
                        <ErrorField
                          show={softErrList.q8Unk_err}
                          message={CommonMsgs.Unknown}
                        />
                        <ErrorField
                          show={hardErrList.q8Empty}
                          message={Demographic_Msgs.Q8}
                        />

                        <ErrorField
                          show={hardErrList.q8_InvalidDate1}
                          message={Demographic_Msgs.q8_InvalidDate1}
                        />
                          <ErrorField
                          show={hardErrList.q8_InvalidDate2}
                          message={Demographic_Msgs.q8_InvalidDate2}
                        />
                      </div>
                    )}
                    {ptDemographicsDetails.q7PtAlive === 0 && (
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>Q9.</span>
                          <span className="quest-text-pad">
                            What was the <u>primary</u> cause of death?
                          </span>
                        </div>
                        <div className=" double-dig-answer">
                          <div className="">
                            <input
                              type="radio"
                              id="q9CauseOfDeathSelect_1"
                              name="q9CauseOfDeath"
                              value={1}
                              checked={
                                ptDemographicsDetails.q9CauseOfDeath === 1
                              }
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q9CauseOfDeathSelect_1">
                              <span className="radio-text-padding">
                                Disease progression
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="radio"
                              id="q9CauseOfDeathSelect_2"
                              name="q9CauseOfDeath"
                              value={2}
                              checked={
                                ptDemographicsDetails.q9CauseOfDeath === 2
                              }
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q9CauseOfDeathSelect_2">
                              <span className="radio-text-padding">
                                COVID-19 infection
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="radio"
                              id="q9CauseOfDeathSelect_3"
                              name="q9CauseOfDeath"
                              value={3}
                              checked={
                                ptDemographicsDetails.q9CauseOfDeath === 3
                              }
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q9CauseOfDeathSelect_3">
                              <span className="radio-text-padding">
                                Other, specify:{" "}
                                <input
                                  className="input-dash"
                                  name="q9CauseOfDeath_Txt"
                                  type="text"
                                  maxLength={100}
                                  value={
                                    ptDemographicsDetails.q9CauseOfDeath_Txt
                                  }
                                  onChange={HandleFieldChange}
                                />
                              </span>
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="radio"
                              id="q9CauseOfDeathSelect_99"
                              name="q9CauseOfDeath"
                              value={99}
                              checked={
                                ptDemographicsDetails.q9CauseOfDeath === 99
                              }
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="q9CauseOfDeathSelect_99">
                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                        </div>
                        <ErrorField
                          show={hardErrList.q9Empty}
                          message={Demographic_Msgs.Q9}
                        />
                        <ErrorField
                          show={softErrList.q9Unk_err}
                          message={CommonMsgs.Unknown}
                        />
                        <ErrorField
                          show={hardErrList.q9EmptyOtherTxt}
                          message="Please enter the text."
                        />
                      </div>
                    )}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q10.</span>
                        <span className="quest-text-pad">
                          What was the date of the last clinical visit?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="date-field-padding leftPad8">
                          <DateControl
                            ctrlId={"q10_lcv_dx_date"}
                            HandleDateChange={HandleDateChange}
                            date={ptDemographicsDetails.q10_lcv_dx_date}
                            showUnkInfoTxt={true}
                            removeErrMsgPadding={true}
                          />
                          {/* <i>
                            If the exact month and/or day is unknown, please
                            enter UNK.
                          </i> */}
                        </div>
                      </div>
                      {/* <ErrorField show={hardErrList.q17Empty} message={demographicScreen_Msgs.Q17} />
                        <ErrorField show={hardErrList.q17aEmpty} message={demographicScreen_Msgs.Q17a} /> */}
                         <ErrorField show={hardErrList.q10Empty} message={Demographic_Msgs.Q10} />
                         <ErrorField show={hardErrList.q10_date_invalid1} message={Demographic_Msgs.Q10_Invalid1}/>
                         <ErrorField show={hardErrList.q10_date_invalid2} message={Demographic_Msgs.Q10_Invalid2}/>
                         <ErrorField show={hardErrList.q10_date_invalid3} message={Demographic_Msgs.Q10_Invalid3}/>                       
                    </div>
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Demographics;
