import React, { useContext, useEffect, useState } from "react";
import { DefaultDate, HTTPResponse, PageDesignConstants, SideMenuItems, ToastMessageType, ToastMessages } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientInformation from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import ErrorField from "../../Components/ErrorField";
import DateControl from "../../Components/DateControl";
import { AddLocalStorageData, DateCriteria1, DateCriteria2, GetDMUID, GetDisplayDate, GetLocalStorageData, GetLoggedInUserID, IsAge18, IsDateCriteriaValid, IsDateEmpty, ParseDate, SetDateState, isStringEmpty } from "../../Helpers/Utilities";
import { CommonError_Msgs, PatientScreening_Msgs } from "../../Helpers/HelperText";
import { AppContext } from "../../Contexts/AppContextProvider";
import { PatientService } from "../../WebApiServices/Patient.service";
import InclusionCriteriaModal from "./InclusionCriteriaModal";

const PatientScreening = () => {
  const [isAllDataFilled, setIsAllDataFilled] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [increment, setIncrement] = useState(0);
  const navigatePaths = {
    prevPage: "/EligibilityCriteria",
    currPage: "/PatientScreening",
    // nextPage: "/Demographics",
    nextPage:(isAllDataFilled && isEligible) ? "/Demographics" : (isAllDataFilled && !isEligible && increment == 2) ? "/ManagePatients" : "/PatientScreening",
  };
  let patientData = GetLocalStorageData("patient");
  patientData = patientData && JSON.parse(patientData);
  const centerId = patientData?.ptCenterId?? 0;
  const abstractorId = patientData?.abstractorId ?? 0;
  const uniqueEncryptedPatientId = patientData?.uniqueEncryptedPatientId ?? 0;
  const surveyDate = patientData?.surveyDate ?? "";
  const userId = GetLoggedInUserID() ?? 0;

  const [isLocked, setIsLocked] = useState(false);
  const pid = GetLocalStorageData("patientId") ?? 0;
  const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout } = useContext(AppContext);

  const [ptDetails, setPtDetails] = useState({
    patientId:null,
    metInclusionCriteria: null,
    s1EpTmtReceived: null,
    s2DtRad: false,

    index_date_epcoritamab: null,
    S2_index_date: DefaultDate,
    s2IndexDtUnk: false,

    s3_conf_lbcl: null,
    s4Subtype: null,
    s4SubtypeOtherTxt: "",

    s5_LBCL_dx_date: null,
    s5_LBCL_dx_date_unk: false,
    s5_LBCL_dx_date_diagnose:DefaultDate,

    S6_dob_dt:DefaultDate
  })
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  const InitialSoftErrList = {
    s1Terminate: false,
    s2Terminate: false,
    s2_unk: false,
    s3Terminate: false,
    s4Terminate: false,
    s4_unk: false,
    s5Terminate: false,
    s5_unk:false,
    s6Terminate: false,
    //s6AgeInvalid:false,
  }
  const [softErrList, setSoftErrList] = useState(InitialSoftErrList)

  const InitialHardErrList = {
    s1Empty:false,
    s2Empty:false,
    s3Empty:false,
    s4Empty:false,
    s4EmptyOtherTxt:false,
    s5Empty:false,
    s6Empty:false,
    s6EmptyOtherTxt:false,
    s6Invalid:false,
    s2DateCriteria1:false,
    s2DateCriteria2:false,
    s6AgeInvalid:false,
  }
  const [hardErrList, setHardErrList] = useState(InitialHardErrList)


  const HandleFieldChange = (e) => {
    const { name, id, value } = e.target;

    switch (name) {
      case "s1EpTmtReceived": {
        const value_int = Number(value);
        setPtDetails((prev) => ({
          ...prev,
          [name]: value_int,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          s1Terminate: value_int === 2 || value_int === 0,
        }))


        setHardErrList((prev) => ({
          ...prev,
          s1Empty: false,
        }));

        if(value_int === 2 || value_int === 0)
        setIsEligible(false);
        break;
      }
//S2
      case "index_date_epcoritamab": {
        const value_int = Number(value);
        setPtDetails((prev) => ({
            ...prev,
            [name]: Number(value),
            S2_index_date: DefaultDate,//reset S2 date
        }));

        setSoftErrList((prev) => ({
          ...prev,
           s2Terminate: value_int === 99,
           s2_unk:value_int === 99,       
        }))

        setHardErrList((prev) => ({
            ...prev,
            s2Empty: false,
            s2DateCriteria1: value_int == 99 ? false : prev.s2DateCriteria1,
            s2DateCriteria2: value_int == 99 ? false : prev.s2DateCriteria2,
        }));

        if(value === 1 ){
          
        setHardErrList((prev) => ({
          ...prev,
          S2Empty: false,
          
      }));
        }

        if(value_int === 99)
        setIsEligible(false);
        break;
    }


//S3

      case "s3_conf_lbcl": {
        const value_int = Number(value);
        setPtDetails((prev) => ({
          ...prev,
          [name]: value_int,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          s3Terminate: value_int === 0,
        }))
        setHardErrList((prev) => ({
          ...prev,
          s3Empty: false,
        }));

        
        if( value_int === 0)
        setIsEligible(false);
        break;
      }

    //   case "index_date": {
    //     setPtDetails((prev) => ({
    //         ...prev,
    //         s2DtRad: true, // Check the date radio button
    //         s2IndexDtUnk: false // Uncheck the "Unknown" option
    //     }));
    //     break;
    // }




    //   case "s2IndexDtUnk": {
    //     setPtDetails((prev) => ({
    //       ...prev,
    //       [name]: !prev.s2IndexDtUnk,
    //       s2DtRad: false,
    //       s2Terminate: !prev.s2IndexDtUnk, //check
    //     }));

    //     setIsEligible(false)
        
    //     break;
    //   }


//S4

      case "s4Subtype": {
        const value_int = Number(value);
        setPtDetails((prev) => ({
          ...prev,
          [name]: value_int,
          s4SubtypeOtherTxt: ""
        }));
        setSoftErrList((prev) => ({
          ...prev,
          s4Terminate: value_int === 99,
          s4_unk: value_int === 99,
        }))
        setHardErrList((prev) => ({
          ...prev,
          s4Empty: false,
          s4EmptyOtherTxt: false,
        }));

        
        if(value_int === 99)
        setIsEligible(false);
        break;
      }

      case "s4SubtypeOtherTxt": {
        setPtDetails((prev) => ({
          ...prev,
          [name]: value,
          s4Subtype: 5,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          s4Terminate: false,
          s4_unk: false,
        }))

        setHardErrList((prev) => ({
          ...prev,
          s4Empty: false,
          s4EmptyOtherTxt: false,
        }));
        

        
        break;
      }

      //S5
      case "s5_LBCL_dx_date": {
        const value_int = Number(value);
        setPtDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          s5_LBCL_dx_date_diagnose: DefaultDate,//reset S5 date
        }));

        setSoftErrList((prev) => ({
          ...prev,
           s5Terminate: value_int === 99,
           s5_unk:value_int === 99,
        }))
        
        setHardErrList((prev) => ({
          ...prev,
          s5Empty: false,
        }));
        break;

        if(value_int === 99)
        setIsEligible(false);
        break;
      }


            //S6
            case "S6_dob_dt": {
            
              const value_int = Number(value);
              setPtDetails((prev) => ({
                ...prev,
                [name]: Number(value),
                S6_dob_dt: DefaultDate,//reset S5 date
              }));
      
              setSoftErrList((prev) => ({
                ...prev,
                 s6Terminate: value_int === 99,
                s6AgeInvalid:(!IsAge18(ptDetails.S6_dob_dt,ptDetails.S2_index_date))
              }))
              
              setHardErrList((prev) => ({
                ...prev,
                s6Empty: false,
              }));
              break;
      
            }




      default:
        break;
    }
  }


  const ValidateForm = () => {
   
    let isValid = true;
    // Validation for qs1
    if (ptDetails.s1EpTmtReceived === null) {
      setHardErrList((prev) => ({
        ...prev,
        s1Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        s1Empty: false,
      }));
    }

    //S2
    if (ptDetails.index_date_epcoritamab) {
   //   const IsDateCriteria_Valid = IsDateCriteriaValid(ptDetails.S2_index_date, ptDetails.s5_LBCL_dx_date_diagnose)
      if (ptDetails.index_date_epcoritamab === 1 && IsDateEmpty(ptDetails.S2_index_date )) {
        isValid = false;
          setHardErrList((prev) => ({
              ...prev,
              s2Empty: true,
              
          }))
      } 
      // else if(ptDetails.index_date_epcoritamab === 99){
      //   setHardErrList((prev) => ({
      //     ...prev,
      //      s2Empty: false,
      // }))
      // }

      else if(ptDetails.s5_LBCL_dx_date!== 99  && !IsDateEmpty(ptDetails.S2_index_date ) && !IsDateEmpty(ptDetails.s5_LBCL_dx_date_diagnose ) && !DateCriteria1(ptDetails.S2_index_date,ptDetails.s5_LBCL_dx_date_diagnose) ){
           setHardErrList((prev) => ({
              ...prev,
               s2Empty: false,
              s2DateCriteria1: true
          }))
          isValid = false;
          }    
          else if(ptDetails.s5_LBCL_dx_date!==99 && !IsDateEmpty(ptDetails.S2_index_date ) && !IsDateEmpty(ptDetails.s5_LBCL_dx_date_diagnose ) && !DateCriteria2(ptDetails.S2_index_date)){
            setHardErrList((prev) => ({
               ...prev,
                s2Empty: false,             
               s2DateCriteria2: true
           }))
           isValid = false;
           }          
           else if(ptDetails.s5_LBCL_dx_date == 99 && !IsDateEmpty(ptDetails.S2_index_date ) && !IsDateEmpty(ptDetails.s5_LBCL_dx_date_diagnose ) && !DateCriteria2(ptDetails.S2_index_date)){
            setHardErrList((prev) => ({
               ...prev,
                s2Empty: false,             
               s2DateCriteria2: true
           }))
           isValid = false;
           }    
    }
    else if(ptDetails.index_date_epcoritamab === null){
      setHardErrList((prev) => ({
        ...prev,
        s2Empty: true,
    }))
    }
    else{
      setHardErrList((prev) => ({
        ...prev,
        s2Empty: false,
        s2DateCriteria: false,
        s2DateCriteria2:false,
        s6AgeInvalid:false
    }))
    }

    


    // validations for qs3
    if (ptDetails.s3_conf_lbcl === null) {
      setHardErrList((prev) => ({
        ...prev,
        s3Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        s3Empty: false,
      }));
    }


    //validations for qs4
      if (ptDetails.s4Subtype === null) {
        setHardErrList((prev) => ({
          ...prev,
          s4Empty: true,
        }));
        isValid = false;
      } 
      
      else if (ptDetails.s4Subtype === 5 && ptDetails.s4SubtypeOtherTxt === ""){
        setHardErrList((prev) => ({
          ...prev,
          s4EmptyOtherTxt: true,
        }));
        isValid = false;
      }
      else {
        setHardErrList((prev) => ({
          ...prev,
          s4Empty: false,
          s4EmptyOtherTxt: false,
        }));
      }


      //S5
    if (ptDetails.s5_LBCL_dx_date) {
      if ( ptDetails.s5_LBCL_dx_date === 1 && IsDateEmpty(ptDetails.s5_LBCL_dx_date_diagnose)) {
        isValid = false;
          setHardErrList((prev) => ({
              ...prev,
              s5Empty: true,
          }))
      }
      
    }
    else if(ptDetails.s5_LBCL_dx_date === null){
      setHardErrList((prev) => ({
        ...prev,
        s5Empty: true,
    }))
  }
  else{
    setHardErrList((prev) => ({
      ...prev,
      s5Empty: false,
    }));
  }

    //S6 
    if (ptDetails.S6_dob_dt) {
      if ( ptDetails.S6_dob_dt  && IsDateEmpty(ptDetails.S6_dob_dt) ) {
        isValid = false;
          setHardErrList((prev) => ({
              ...prev,
              s6Empty: true,
            // s6AgeInvalid:IsAge18(ptDetails.S6_dob_dt, ptDetails.S2_index_date)
          }))
      }


      else if( !IsDateEmpty(ptDetails.S6_dob_dt ) && !IsDateEmpty(ptDetails.S2_index_date ) && !IsAge18(ptDetails.S6_dob_dt,ptDetails.S2_index_date)){
        isValid = false;
        setHardErrList((prev) => ({
          ...prev,
          s6AgeInvalid: true,
      }))
      }
    }
    else if(ptDetails.S6_dob_dt === DefaultDate){
      setHardErrList((prev) => ({
        ...prev,
        s6Empty: true,
    }))
    isValid = false;
  }
  else{
    setHardErrList((prev) => ({
      ...prev,
      s6Empty: false,
  }))
  isValid = true;
  }
// else{
//   let currentYear = (new Date(ptDetails.S6_dob_dt).getFullYear());
//   let totalYears = currentYear - parseInt(ptDetails.S6_dob_dt);
//   if (totalYears < 18) {
//     isValid = false;
//       setHardErrList((prev) => ({
//           ...prev,
//           s6Invalid: true
//       }))
//   }
// }
      setIsAllDataFilled(isValid);
    return isValid;
  };




  const HandleDateChange = (name, date, datePart) => {
   
    switch (name) {
        case "S2_index_date": {
            setPtDetails((prev) => ({
                ...prev,
                index_date_epcoritamab: 1,
                S2_index_date: SetDateState(prev.S2_index_date, date, datePart),
                
            }));
            setSoftErrList((prev) => ({
              ...prev,
               s2_unk:null,
               s2DateCriteria: false,
               s2DateCriteria: prev.value !== 99 ? false : prev.s2DateCriteria,
               s2Terminate:false
               
            }))

           // const DateCriteria = IsDateCriteriaValid(ptDetails.S2_index_date, ptDetails.s5_LBCL_dx_date_diagnose);
            if(ptDetails.S2_index_date){
            setHardErrList((prev) => ({
                ...prev,
                s2Empty: false,
                s2DateCriteria: false,
                s2DateCriteria1:false,
                s2DateCriteria2:false,
            })); 
          }else{
            setHardErrList((prev) => ({
              ...prev,
              s2Empty: false,
              s2DateCriteria: false,
              // s2DateCriteria1:false,
              // s2DateCriteria2:false,
          })); 
          }
            break;           
        }


        case "s5_LBCL_dx_date_diagnose":  {
          setPtDetails((prev) => ({
            ...prev,
            s5_LBCL_dx_date: 1,
            s5_LBCL_dx_date_diagnose: SetDateState(prev.s5_LBCL_dx_date_diagnose, date, datePart),
        }));

        setSoftErrList((prev) => ({
          ...prev,
           s5_unk:null,
           s5Terminate:false,
        }))


        setHardErrList((prev) => ({
            ...prev,
            s5Empty: false,
        })); 
        break;
        }


        case "S6_dob_dt":  {
          setPtDetails((prev) => ({
            ...prev,
            S6_dob_dt: SetDateState(prev.S6_dob_dt, date, datePart),
        }));

        // const isAgeValid = IsAge18(ptDetails.S6_dob_dt, ptDetails.S2_index_date);

        // setSoftErrList((prev) =>({
        //   ...prev,
        //   //s6AgeInvalid: !isAgeValid,
        // }));
        setHardErrList((prev) => ({
            ...prev,
            s6Empty: false,
            s6AgeInvalid: false,
        })); 
        break;
        }



        default: break;
    }
};
// const HandleDateChange=()=> {

// }

  // const HandleDateChange = (e) => {
  //   const { name, id, value } = e.target;

  //   switch (name) {    
  //     case "index_date": {
  //       setPtDetails((prev) => ({
  //           ...prev,
  //           q2Diagnosis_dt: value,
  //           s2DtRad: true, // Ensure the date radio button is checked when a date is selected
  //           s2IndexDtUnk: false // Uncheck the "Unknown" option
  //       }));
  //       break;
  //   }
  //     default:
  //       break;  
  //   }
  // }


  const SavePage= async () => {
  
    try {
        ToggleLoader(true);

    

        //inclusion criteria
        setPtDetails((prev) => ({
            ...prev,
            metInclusionCriteria: null
        }))

        //remove soft errors on click of Save
        setSoftErrList(InitialSoftErrList);
        setHardErrList(InitialHardErrList);


        if (!ValidateForm()) {
            setShowTopErrMsg(true)
            ToggleLoader(false);
            return false;
        }
        setShowTopErrMsg(false);
        
        const payload = {
          metInclusionCriteria: ValidateEligibility(),
          AbstractorId: userId,
          siteid: centerId,
          encryptedpatientid: ptDetails.encryptedpatientid,
          uniquepatientid: ptDetails.uniquepatientid,
          chart_abstract_date: new Date().toISOString(),
          patientId:pid,
          receivedtreatment: ptDetails.s1EpTmtReceived,
 
          // index_date: ptDetails.s2DtRad ? ptDetails.q2Diagnosis_dt : null,
          indexdate: ptDetails.index_date_epcoritamab === 1 ? ParseDate(ptDetails.S2_index_date) : null,
          indexdatedmuid:ptDetails.index_date_epcoritamab === 1 ? GetDMUID(ptDetails.S2_index_date):null ,
         // indexdatedmuid: ptDetails.s2DtRad ? 2 : null,
          indexdateunk: ptDetails.index_date_epcoritamab === 99 ?ptDetails.index_date_epcoritamab:false, 
          
          
          lbcldxconfirmed: ptDetails.s3_conf_lbcl,

          lbclsubtype:ptDetails.s4Subtype,
          lbclsubtypeothtxt: ptDetails.s4Subtype === 5 ? ptDetails.s4SubtypeOtherTxt : null,


          lbcldxdate: ptDetails.s5_LBCL_dx_date === 1 ? ParseDate(ptDetails.s5_LBCL_dx_date_diagnose) : null,
          lbcldxdatedmuid: ptDetails.s5_LBCL_dx_date === 1 ? GetDMUID(ptDetails.s5_LBCL_dx_date_diagnose):null,
          lbcldxdateunk:  ptDetails.s5_LBCL_dx_date === 99 ?ptDetails.s5_LBCL_dx_date : false,





          
          birthdate: ParseDate(ptDetails.S6_dob_dt),
          birthdatedmuid: ptDetails.S6_dob_dt ? GetDMUID(ptDetails.S6_dob_dt):null,


          createdby: GetLoggedInUserID() ?? 0,
          editedby: GetLoggedInUserID() ?? 0,
     }

     const response = await PatientService.SavePatient(payload);

        ToggleLoader(false);
        if (response.status !== HTTPResponse.OK) {

            if (response.status === HTTPResponse.Unauthorized)
                HandleSessionTimeout();
            else
                throw response.error;
        } else {
        
          setIncrement(increment+1);
            //AddLocalStorageData("patientId", response?.data?.patientId);//remove
            setPtDetails((prev) => ({
                ...prev,
                metInclusionCriteria: response?.data?.metInclusionCriteria,
                patientId:response?.data.patientId
            }))
       //     ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
       ShowToast("Patient has been saved successfully", ToastMessageType.Success);

      AddLocalStorageData("patientId", response?.data?.patientId)
      AddLocalStorageData("uniqueEncryptedPatientId", response?.data?.uniquePatientId);
      AddLocalStorageData("surveyDate", response?.data?.chartAbstractionDate)


      const ptArr = {
        patientId: response?.data?.patientId ?? 0,
        uniqueEncryptedPatientId: response?.data?.uniquePatientId ?? 0,
        abstractorId: response?.data?.abstractorId ?? 0,
        surveyDate: response?.data?.chartAbstractionDate,
        ptCenterId: response?.data?.siteId,
       // patientId:response?.data?.patientId
      };

      
     AddLocalStorageData("patient", JSON.stringify(ptArr))

            ToggleLoader(true);
            await LoadData();
            ToggleLoader(false);


            return response?.data?.metInclusionCriteria
        }
    } catch (error) {
        ToggleLoader(false);
        ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
    }
}


useEffect(() => {

  async function FetchData() {
    if (!isNaN(pid) && Number(pid) !== 0) {

        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
    }
  }
  FetchData();
}, [])


async function LoadData() {
  try {
    
  
    const patientId = pid === 0 ? ptDetails.patientId : pid;

      const response = await PatientService.LoadPatient(patientId);
     
      if (response?.status === HTTPResponse.OK) {
          const { data } = response;

          setPtDetails((prev) => ({
              ...prev,
              metInclusionCriteria: data.metInclusionCriteria
              ,
              s1EpTmtReceived: data.receivedTreatment,
              // != null? data.receivedTreatment
              // :null,


              // s2DtRad: data.indexdatedmuid === 2,
              
              
              S2_index_date: !isStringEmpty(data.indexDate)?
              GetDisplayDate(data.indexDate,data.indexDateDmuid) : DefaultDate,
           

             // S2_index_date: GetDisplayDate(data.indexDate, data.indexDateDmuid) ?? null,
              s2IndexDtUnk: data.indexdateunk ?? false,
              index_date_epcoritamab: data.indexDateUnk ? 99: data.indexDate != null? 1 : null,


              s3_conf_lbcl: data.lbclDxConfirmed ?? null,

              // s4Subtype: data.lbclSubType,
               s4Subtype: data.lbclSubtypeOthTxt? 5: data.lbclSubtype,
               s4SubtypeOtherTxt: data.lbclSubtypeOthTxt ?? "",

            //  // s4Subtype
            //  s4Subtype: data.lbclSubtypeothTxt ? 5 : data.lbclsubtype ?? null,
            //  s4SubtypeOtherTxt: data.lbclSubtypeothTxt ?? "",

              // s5_LBCL_dx_date_diagnose: GetDisplayDate(data.lbclDxDate, data.lbclDxDateDmuid) ?? null,
              // s5_LBCL_dx_date_unk: data.lbclDxdateUnk?? false,


              s5_LBCL_dx_date_diagnose: !isStringEmpty(data.lbclDxDate)?
              GetDisplayDate(data.lbclDxDate,data.lbclDxDateDmuid) : DefaultDate,

              s5_LBCL_dx_date: data.lbclDxDateUnk ? 99: data.lbclDxDate != null? 1 : null,


              S6_dob_dt: !isStringEmpty(data.birthDate)?
              GetDisplayDate(data.birthDate,data.birthDateDmuid) : DefaultDate,

          //  S6_dob_dt: GetDisplayDate(data.birthDate,data.birthDateDmuid) ?? null,
              // s5_LBCL_dx_date: data.lbclDxDate ?? null,
              // s5_LBCL_dx_date_unk: data.lbclDxdateUnk ?? false,
          }));

          // setIsLocked(data.isLocked ?? false);
      } else {
          if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
          } else {
              throw response.error;
          }
      }
  } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
  }
}


//  /* <summary>
// date: 
// Name: 
// description: Save New Patient 
// <summary>*/
// const AddNewPatient = async () => {
//   try {
// console.log("PtDEtails",ptDetails)
//     const payload = {
//          metInclusionCriteria: ValidateEligibility(),
//          AbstractorId: userId,
//          siteid: centerId,
//        encryptedpatientid: ptDetails.encryptedpatientid,
//        uniquepatientid: ptDetails.uniquepatientid,
//          chart_abstract_date: new Date().toISOString(),
//          receivedtreatment: ptDetails.s1EpTmtReceived,

//          index_date: ptDetails.s2DtRad ? ptDetails.q2Diagnosis_dt : null,
//          indexdatedmuid: ptDetails.s2DtRad ? 2 : null,
//          indexdateunk: ptDetails.s2IndexDtUnk,
//          lbcldxconfirmed: ptDetails.s3_conf_lbcl,
//          lbclsubtypeothtxt: ptDetails.s4Subtype === 5 ? ptDetails.s4SubtypeOtherTxt : null,
//          lbcldxdate: ptDetails.s5_LBCL_dx_date,
//          lbcldxdatedmuid: ptDetails.s5_LBCL_dx_date ? 3 : null,
//          lbcldxdateunk: ptDetails.s5_LBCL_dx_date_unk,
//          birthdate: ptDetails.birth_date,
//          birthdatedmuid: ptDetails.birth_date ? 4 : null,
//          createdby: GetLoggedInUserID() ?? 0,
//          editedby: GetLoggedInUserID() ?? 0,
//     }
//     ToggleLoader(true)
//     console.log("check",payload);
//     debugger
//     const response = await PatientService.AddNewPatient(payload);
//     console.log("resssssss",response);
//     ToggleLoader(false)
//     if (response?.status === HTTPResponse.OK) {
 
//       AddLocalStorageData("patientId", response?.data?.patientId)
//       AddLocalStorageData("uniqueEncryptedPatientId", response?.data?.uniquePatientId);
//       AddLocalStorageData("surveyDate", response?.data?.chartAbstractionDate)
      
// console.log("PDATA",patientData)

//       const ptArr = {
//         patientId: response?.data?.patientId ?? 0,
//         uniqueEncryptedPatientId: response?.data?.uniquePatientId ?? 0,
//         abstractorId: response?.data?.abstractorId ?? 0,
//         surveyDate: response?.data?.chartAbstractionDate,
//       };
//      AddLocalStorageData("patient", JSON.stringify(ptArr))

//       ShowToast("Patient has been saved successfully", ToastMessageType.Success);
//       return true;
//     } else {
//       if(response?.status === HTTPResponse.Unauthorized){
//         HandleSessionTimeout();
//       }else{
//         throw response.error;
//       }
//     }
//   } catch {
//     ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
//   }
// }




const ValidateEligibility = () => {

  try {
      let result = true;
      if (ptDetails.s1EpTmtReceived === 2
          || ptDetails.s1EpTmtReceived === 0
          || ptDetails.index_date_epcoritamab === 99 || ptDetails.s3_conf_lbcl === 0 || ptDetails.s4Subtype === 99
          || ptDetails.s5_LBCL_dx_date === 99
          )
          result = false;

      setIsEligible(result);

      return result;
  }
  catch {
  }
}
const [showModal, setShowModal] = useState(false);
const openModal = (event) => {

  event.preventDefault();
  setShowModal(true);

};

const handleModalClose = () => setShowModal(false);
const handleModalOpen = (e) =>{
  setShowModal(true);
  e.preventDefault();
} 

// useEffect(()=>{
//   console.log(GetLocalStorageData("patientId"))
//   console.log("iD",pid)
// })
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientInformation />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.PatientScreening} />

          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>PATIENT SCREENING</h4>
              </div>
              {/* {!showTopErrMsg && ptDetails.metInclusionCriteria != null && (
                ptDetails.metInclusionCriteria ?
                  (<div className="survey-section-content-message">
                    <p>
                      {PatientScreening_Msgs.EligiblePatient}
                    </p>
                  </div>)
                  :
                  ((<div className="clean-error">
                    <p>
                    Based on your answer, the patient does not meet the <a href="#" onClick={handleModalOpen}>inclusion criteria</a> for this study. 
                    Please double-check your answer and correct the entry, if necessary. Then click “Next” to continue.
                            </p>
                  </div>)
                  
              ))} */}
                {!showTopErrMsg && ptDetails.metInclusionCriteria != null && (
  ptDetails.metInclusionCriteria ? (
    <div className="survey-section-content-message">
      <p>
        {PatientScreening_Msgs.EligiblePatient}
      </p>
    </div>
  ) : ptDetails.metInclusionCriteria != null &&  increment === 2 ? (
    <div className="clean-error">
      <p>
        We are sorry but the patient you selected does not meet the inclusion criteria for this study. Please review the criteria again and select a different patient.
      </p>
    </div>
  ) : (
    <div className="clean-error">
      <p>
        Based on your answer, the patient does not meet the <a href="#" onClick={handleModalOpen}>inclusion criteria</a> for this study.
        Please double-check your answer and correct the entry, if necessary. Then click “Next” to continue.
      </p>
    </div>
  )
)}

              {showTopErrMsg && <div className="clean-error ">
                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
              </div>
              }
              {showModal && <InclusionCriteriaModal show={showModal} handleClose={handleModalClose} />}
             
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div className="question-answer">
                    <div className="question-bot-sapce single-dig-quest">
                      {/* <div className="survey-question-section">   */}
                      <div className="Patient-screening-intructions">
                        <span>
                          <div className="font-style-spacing">
                            Site ID  (Numeric identity of healthcare provider
                            site):{" "}
                            <label className="font-style-value" htmlFor="">
                              {centerId !== 0 && centerId}
                            </label>
                          </div>
                          <div className="font-style-spacing">
                            Abstractor ID (Numeric identity of healthcare
                            provider):{" "}
                            <label className="font-style-value" htmlFor="">
                              {abstractorId !== 0 && abstractorId}
                            </label>
                          </div>
                          <div className="font-style-spacing">
                            Patient ID  (Scrambled unique identifier):{}
                            <label className="font-style-value" htmlFor="">
                              {uniqueEncryptedPatientId !== 0 &&
                                uniqueEncryptedPatientId}  
                            </label>
                          </div>
                          <div className="font-style-spacing">
                            Date of chart abstraction initiation:{" "}
                            <label className="font-style-value" htmlFor="">
                              {surveyDate !== "" && surveyDate}
                            </label>
                          </div>
                        </span>
                      </div>
                      <br></br>
                      <div className="">
                        <span>
                          The assigned unique patient identifier for this
                          patient chart is {""}
                          {uniqueEncryptedPatientId !== 0 && (
                            <span
                              style={{ color: PageDesignConstants.fontColor }}
                            >
                              {uniqueEncryptedPatientId}
                            </span>
                          )}
                          . Please make a note of the unique patient identifier
                          for your records. Unique patient identifiers will be
                          used to help you keep track of the charts you have
                          abstracted for this study.
                          <br />
                          <br />
                          Now we will begin to collect information on the
                          patient you selected. The first few questions will
                          help confirm that the patient meets the study
                          inclusion criteria. If a patient fails to meet{" "}

                          {" "}
                          all of{" "}

                          the inclusion criteria, you will be asked to select a
                          new patient who is eligible for the study and restart
                          this section.
                        </span>
                      </div>{" "}
                      <br></br>
                      {/* </div> */}
                      <div className="question question-weight">
                        <span>S1.</span>
                        <span className="quest-text-pad">
                          Did the patient receive treatment with epcoritamab?
                        </span>
                      </div>
                      <div className="answer-pad-left">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s1EpTmtReceived_1"
                              name="s1EpTmtReceived"
                              value={1}
                              checked={
                                ptDetails.s1EpTmtReceived === 1
                              }
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              Yes, in a <b>non-clinical trial setting</b>{" "}
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s1EpTmtReceived_2"
                              name="s1EpTmtReceived"
                              value={2}
                              checked={
                                ptDetails.s1EpTmtReceived === 2
                              }
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              Yes, in a <b>clinical trial setting</b>
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s1EpTmtReceived_0"
                              name="s1EpTmtReceived"
                              value={0}
                              checked={
                                ptDetails.s1EpTmtReceived === 0
                              }
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">No</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.s1Empty} message={PatientScreening_Msgs.QS1} style={{ paddingLeft: '24px' }}  />
                      <ErrorField show={softErrList.s1Terminate} message={PatientScreening_Msgs.INELIGIBLE} style={{ paddingLeft: '15px' }} />
                      <br />
                      <div className="question question-weight">
                        <span>S2.</span>
                        <span className="quest-text-pad">
                          When did the patient initiate treatment with
                          epcoritamab?
                        </span>
                      </div>
                      <div className="answer-pad-left">
                        <div className="date-field-padding">
                          <input
                            type="radio"
                            id="index_date_1"
                            name="index_date_epcoritamab"
                            value={1}
                            checked={ptDetails.index_date_epcoritamab === 1}
                            onChange={HandleFieldChange}
                          />
                          <span className="radio-text-padding">
                            <DateControl
                              ctrlId={"S2_index_date"}
                              HandleDateChange={HandleDateChange}
                              date={ptDetails.S2_index_date} 
                              showUnkInfoTxt={true}
                            />
                             {/* <i>If the exact month and/or day is unknown, please enter UNK.</i>   */}
                          </span>
                        </div>
                        <div className="option-without-input">
                          <input
                            type="radio"
                            id="index_date_99"
                            name="index_date_epcoritamab"
                            checked={ptDetails.index_date_epcoritamab === 99}
                            value={99}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="index_date_99">
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.s2Empty} message={PatientScreening_Msgs.QS2} />
                      <ErrorField show={hardErrList.s2DateCriteria1} message={PatientScreening_Msgs.DateCriteria1} style={{ paddingLeft: '15px' }}  />
                      <ErrorField show={hardErrList.s2DateCriteria2} message={PatientScreening_Msgs.DateCriteria2} style={{ paddingLeft: '15px' }}  />
                      <ErrorField show={softErrList.s2Terminate} message={PatientScreening_Msgs.INELIGIBLE} style={{ paddingLeft: '15px' }} />
                      <ErrorField show={softErrList.s2_unk} message={CommonError_Msgs.UnknownSelect} style={{ paddingLeft: '15px' }} />
                     <br />
                      <div className="question question-weight">
                        <span>S3.</span>
                        <span className="quest-text-pad">
                          Does the patient have a confirmed diagnosis of large
                          B-cell lymphoma (LBCL)?  Subtypes of LBCL include, but
                          are not limited to, diffuse large B-cell lymphoma
                          (DLBCL), high-grade B-cell lymphoma (HGBCL), primary
                          mediastinal large B-cell lymphoma (PMBCL), and grade
                          3b follicular lymphoma (FL).
                        </span>
                      </div>
                      <div className="answer-pad-left">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s3_conf_lbcl_1"
                              name="s3_conf_lbcl"
                              value={1}
                              checked={ptDetails.s3_conf_lbcl === 1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s3_conf_lbcl_0"
                              name="s3_conf_lbcl"
                              value={0}
                              checked={ptDetails.s3_conf_lbcl === 0}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">No</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.s3Empty} message={PatientScreening_Msgs.QS3} />
                      <ErrorField show={softErrList.s3Terminate} message={PatientScreening_Msgs.INELIGIBLE} style={{ paddingLeft: '15px' }} />
                      <br />
                      <div className="question question-weight">
                        <span>S4.</span>
                        <span className="quest-text-pad">
                          Which subtype of LBCL is the patient diagnosed with?
                        </span>
                      </div>
                      <div className="answer-pad-left">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s4Subtype_1"
                              name="s4Subtype"
                              value={1}
                              checked={ptDetails.s4Subtype === 1}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">DLBCL </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s4Subtype_2"
                              name="s4Subtype"
                              value={2}
                              checked={ptDetails.s4Subtype === 2}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">HGBCL </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s4Subtype_3"
                              name="s4Subtype"
                              value={3}
                              checked={ptDetails.s4Subtype === 3}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">PMBCL </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s4Subtype_4"
                              name="s4Subtype"
                              value={4}
                              checked={ptDetails.s4Subtype === 4}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              Grade 3 FL{" "}
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s4Subtype_5"
                              name="s4Subtype"
                              value={5}
                              checked={ptDetails.s4Subtype === 5}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">
                              Other, specify: {""}
                              <input
                                className="input-dash"
                                name="s4SubtypeOtherTxt"
                                type="text"
                                maxLength={100}
                                value={ptDetails.s4SubtypeOtherTxt}
                                onChange={HandleFieldChange}
                              />
                            </span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s4Subtype_99"
                              name="s4Subtype"
                              value={99}
                              checked={ptDetails.s4Subtype === 99}
                              onChange={HandleFieldChange}
                            />
                            <span className="radio-text-padding">Unknown </span>
                          </label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.s4Empty} message={PatientScreening_Msgs.QS4} />
                      <ErrorField show={softErrList.s4Terminate} message={PatientScreening_Msgs.INELIGIBLE} style={{ paddingLeft: '15px' }} />
                      <ErrorField show={softErrList.s4_unk} message={CommonError_Msgs.UnknownSelect} style={{ paddingLeft: '15px' }} />
                      <ErrorField
                        show={hardErrList.s4EmptyOtherTxt}
                        message={PatientScreening_Msgs.QS4OtherTxtEmpty}
                      />
                      <br />
                      {/* <div className="question-bot-sapce single-dig-quest"> */}
                      <div className="question question-weight">
                        <span>S5.</span>
                        <span className="quest-text-pad">
                          When was the patient first diagnosed with LBCL?
                        </span>
                      </div>
                      <div className="answer-pad-left">
                        <div className="date-field-padding">
                          <input
                            type="radio"
                            id="s5_LBCL_dx_date_1"
                            name="s5_LBCL_dx_date"
                            value={1}
                            checked={ptDetails.s5_LBCL_dx_date === 1}
                            onChange={HandleFieldChange}
                          />
                          <span className="radio-text-padding">
                            <DateControl
                              ctrlId={"s5_LBCL_dx_date_diagnose"}
                              HandleDateChange={HandleDateChange}
                              date={ptDetails.s5_LBCL_dx_date_diagnose}  
                              showUnkInfoTxt={true}                
                            />
                            {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                          </span>
                        </div>
                        <div className="option-without-input">
                        <label >
                          <input
                            type="radio"
                            id="s5_LBCL_dx_date_99"
                            name="s5_LBCL_dx_date"
                            value={99}
                            checked={ptDetails.s5_LBCL_dx_date === 99}
                            onChange={HandleFieldChange}
                          />
                          
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.s5Empty} message={PatientScreening_Msgs.QS5} />
                      <ErrorField show={softErrList.s5Terminate} message={PatientScreening_Msgs.INELIGIBLE} style={{ paddingLeft: '15px' }} />
                      <ErrorField show={softErrList.s5_unk} message={CommonError_Msgs.UnknownSelect} style={{ paddingLeft: '15px' }} />

                      <br />
                      {/* </div> */}
                      {/* <div className="question-bot-sapce single-dig-quest"> */}
                      <div className="question question-weight">
                        <span>S6.</span>
                        <span className="quest-text-pad">
                          What is the patient’s date of birth?
                        </span>
                      </div>
                      <div className="answer-pad-left">
                        <div className="date-field-padding leftPad8">
                          <DateControl
                            ctrlId={"S6_dob_dt"}
                            date={ptDetails.S6_dob_dt}
                            HandleDateChange={HandleDateChange}
                            removeErrMsgPadding={true}
                            showUnkInfoTxt={true}
                          />
                          {/* <i>If the exact month and/or day is unknown, please enter UNK.</i> */}
                        </div>

                      </div>
                      {/* </div> */}
                      <ErrorField show={softErrList.s6Terminate} message={PatientScreening_Msgs.INELIGIBLE} style={{ paddingLeft: '15px' }} />
                      <ErrorField show={hardErrList.s6Empty} message={PatientScreening_Msgs.QS6} />
                      <ErrorField show={hardErrList.s6AgeInvalid} message={PatientScreening_Msgs.INVALID} />
                              
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />             
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </>
  );
};

export default PatientScreening;
