import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ErrorField from "./ErrorField";
import { CommonError_Msgs } from "../Helpers/HelperText";
import { DatePart } from "../Helpers/Enums";
import { IsDateEmpty, IsDateValid, isStringEmpty } from "../Helpers/Utilities";

const DateControl = ({
  hideDay,
  hideMonth,
  //marginLeft = true,
  ctrlId,
  HandleDateChange,
  // optionId,
  //resetDate,
  resetDay,
  resetMonth,
  resetYear,
  date,
  showUnkInfoTxt = false,
  removeErrMsgPadding = false,
  hideUnk = false
}) => {
  const defaultDate = "MM/DD/YYYY";
  const defaultDay = "DD";
  const defaultMonth = "MM";
  const defaultYear = "YYYY";
  //const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [selectedDay, setSelectedDay] = useState(defaultDay);
  const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  const [dateInvalid, setDateInvalid] = useState(false);

  const yearArray = [];

  let dayArray = Array.from({ length: 31 }, (_, index) =>
    index < 9 ? `0${index + 1}` : index + 1
  );

  let monthArray = Array.from({ length: 12 }, (_, index) =>
    index < 9 ? `0${index + 1}` : index + 1
  );

  if (!hideUnk) {
    dayArray.unshift("UNK");
    monthArray.unshift("UNK");
  }

  const today = new Date();

  for (let i = today.getFullYear(); i >= 1920; i--) {
    yearArray.push(i);
  }

  const preventManualInput = (e) => {
    e.preventDefault();
  };
  // let inputProps = {
  //   readOnly: true,
  //   onBeforeInput: preventManualInput,
  //   value: date != null && date != "" ? date : selectedDate,
  //   style: {
  //     width: "130px",
  //     marginRight: "5px",
  //     height: "25px",
  //     marginLeft: marginLeft === true ? "5px" : "0",
  //   },
  // };
  // const HandleFullDateChange = (e) => {
  //   if (e.isValid()) {
  //     setSelectedDate(e.format("MM/DD/yyyy"));
  //     HandleDateChange(ctrlId, e.format("MM/DD/yyyy"), optionId);
  //   }
  // };
  /* <summary>
  date: 06-04-2024
  Name: AP
  description: Set the min and max dates for the calendar
  <summary>*/
  // const SetDateRange = (current) => {
  //   return current.isSameOrAfter("01/01/1920");//&& current.isSameOrBefore("12/01/2023");
  // } 
  const HandleDaySelection = (e) => {
    const day = e.target.textContent;
    setSelectedDay(day);
    HandleDateChange(ctrlId, day, DatePart.Day);
  };
  const HandleMonthSelection = (e) => {
    const month = e.target.textContent;
    setSelectedMonth(month);
    HandleDateChange(ctrlId, month, DatePart.Month);
  };
  const HandleYearSelection = (e) => {
    const year = e.target.textContent;
    setSelectedYear(year);
    HandleDateChange(ctrlId, year, DatePart.Year);
  };
  /* <summary>
  date: 06-04-2024
  Name: AP
  description: Set date to defaut value
  <summary>*/
  // useEffect(() => {
  //   debugger
  //   if (resetDate) {
  //     setSelectedDay(defaultDay);
  //     setSelectedMonth(defaultMonth);
  //     setSelectedYear(defaultYear);
  //   }
  //   if (resetMonth) setSelectedMonth(defaultMonth);
  //   if (resetYear) setSelectedYear(defaultYear);
  // }, [resetDate, resetMonth, resetYear]);

  useEffect(() => {
    const parts = date?.split('/') ?? [];
    let month = parts[0] ?? "";
    let day = parts[1] ?? "";
    let year = parts[2] ?? "";
    setDateInvalid(false);
    SetDisplayDate(month, day, year);

    if (!IsDateEmpty(date)) {
      setDateInvalid(!IsDateValid(date));
    }
  }, [date]);

  const SetDisplayDate = (month = "", day = "", year = "") => {
    setSelectedMonth(month !== "" ? month : defaultMonth);
    setSelectedDay(day !== "" ? day : defaultDay);
    setSelectedYear(year !== "" ? year : defaultYear);
  }

  //const monthDisplay = !isStringEmpty(date) && (`${date}`?.split('/')[0] ?? "") !== "" ? `${date}`?.split('/')[0] : selectedMonth;
  //const dayDisplay = !isStringEmpty(date) && (`${date}`?.split('/')[1] ?? "") !== "" ? `${date}`?.split('/')[1] : selectedDay;
  //const yearDisplay = !isStringEmpty(date) && (`${date}`?.split('/').pop() ?? "") !== "" ? `${date}`?.split('/').pop() : selectedYear;
  return (
    <>
      {!hideMonth && (
        <Dropdown className='custom-dropdown-caret'>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className="custom-dropdown-button"
          >
            {selectedMonth}
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-dropdown-menu">
            {monthArray?.map((m, i) => (
              <Dropdown.Item
                as="button"
                key={i}
                className={`custom-dropdown-item ${m === selectedMonth && 'custom-dropdown-item-selected'}`}
                style={{ width: "90px" }}
                onClick={HandleMonthSelection}
                closeonselect="true"
              >
                {m}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {!hideDay && (
        <Dropdown className='custom-dropdown-caret'>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className="custom-dropdown-button"
          >
            {selectedDay}
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-dropdown-menu">
            {dayArray?.map((d, i) => (
              <Dropdown.Item
                as="button"
                key={i}
                className={`custom-dropdown-item ${d === selectedDay && 'custom-dropdown-item-selected'}`}
                style={{ width: "90px" }}
                onClick={HandleDaySelection}
                closeonselect="true"
              >
                {d}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {(
        <Dropdown className='custom-dropdown-caret'>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className="custom-dropdown-button"
          >
            {selectedYear}
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-dropdown-menu">
            {yearArray?.map((y, i) => (
              <Dropdown.Item
                as="button"
                key={i}
                className={`custom-dropdown-item ${y == selectedYear && 'custom-dropdown-item-selected'}`}
                style={{ width: "90px" }}
                onClick={HandleYearSelection}
                closeonselect="true"
              >
                {y}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {showUnkInfoTxt &&
        <i>
          {hideDay ?
            "If the exact month is unknown, please enter UNK."
            : "If the exact month and/or day is unknown, please enter UNK."
          }
        </i>
      }
      {
        dateInvalid &&
        <ErrorField

          show={true}
          message={CommonError_Msgs.InvalidDate}
          style={removeErrMsgPadding ? { paddingLeft: '0px' } : {}}
        />
      }
    </>
  );
};

export default DateControl;
