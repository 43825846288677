import WebServiceWrapper from "./WebServiceWrapper";

export const ClinicalService = {
    GetClinicalData,
    SaveClinicalData
};

function GetClinicalData(patientId) {
    return WebServiceWrapper.Get(`/clinical/GetClinicalData?patientid=${patientId}`);
}

function SaveClinicalData(payload) {
    return WebServiceWrapper.Post("/clinical/SaveClinicalData", payload);
}